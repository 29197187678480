import {
  NEXT_STEP,
  PREV_STEP,
  TOGGLE_NEXT,
  EDIT_STEP,
  SET_DRAWER,
  NEXT_BRACKET,
  PREV_BRACKET,
} from "../actions/types";

const initialState = {
  current: 1,
  next: true,
  drawer: false,
  currentBracket: 1,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case NEXT_STEP:
      return {
        ...state,
        current: state.current + 1,
      };

    case PREV_STEP:
      return {
        ...state,
        current: state.current === 1 ? 1 : state.current - 1,
      };

    case TOGGLE_NEXT:
      return {
        ...state,
        next: payload.next,
      };

    case EDIT_STEP:
      return {
        ...state,
        current: payload.step,
      };

    case SET_DRAWER:
      return {
        ...state,
        drawer: payload,
      };

    case NEXT_BRACKET:
      return {
        ...state,
        currentBracket: state.currentBracket + 1,
      };

    case PREV_BRACKET:
      return {
        ...state,
        currentBracket: state.currentBracket - 1,
      };

    default:
      return state;
  }
}
