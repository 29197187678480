import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Title from "./layout/Title";
import { toggleNext } from "../actions/step";
import { addBracket, removeBracket, toggleBracket } from "../actions/order";
import numWords from "num-words";
import FaqModal from "./layout/FaqModal";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Checkbox from "@material-ui/core/Checkbox";
/* import Popper from "@material-ui/core/Popper";
import PopperCard from "./layout/PopperCard"; */
import Grow from "@material-ui/core/Grow";
import Grid from "@material-ui/core/Grid";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

const Bracket = ({
  tvSizes,
  toggleNext,
  brackets,
  addBracket,
  removeBracket,
  toggleBracket,
  currentBracket,
  next,
}) => {
  const useStyles = makeStyles(() => ({
    body: {
      paddingTop: 36,
      minHeight: 787,
      fontFamily: "Montserrat, sans-serif",
      "@media (max-width: 768px)": {
        paddingTop: 24,
        minHeight: 651,
      },
    },

    baseDiv: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    root1: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: 20,
      padding: 17,
      backgroundColor: "#fff",
      margin: "26px 0px",
      left: 0,
      position: "relative",
      boxShadow: "0 2px 6px 0 rgba(0, 0, 0, .1)",
      border: "1px solid rgba(218,220,228,.1)",
      height: "50px",
      "@media (max-width: 768px)": {
        borderRadius: 10,
        animation: "none!important",
        left: 0,
        boxShadow: "none",
        border: "none",
      },
    },

    sizeHeader1: {
      display: "flex",
      padding: "45px 20px 2px 20px",
      marginBottom: -20,
      fontSize: 12,
      color: "#b7b7b7",
      fontWeight: 500,
      letterSpacing: "0.5px",
      lineHeight: 3,
      left: 0,
      opacity: 1,
      position: "relative",
      "@media (max-width: 768px)": {
        padding: "25px 20px 2px 20px",
        animation: "none!important",
        fontSize: 10,
      },
    },
    nameSide: {
      width: "64%",
      marginLeft: "25px",
      color: "#000",
    },
    qtySide: {
      width: "36%",
      textAlign: "center",
      color: "#000",
    },
    nameDiv: {
      fontWeight: 500,
      fontSize: 17,
      color: "#000",
      fontFamily: "Montserrat, sans-serif",
      "@media (max-width: 768px)": {
        fontSize: 14,
      },
    },
    qtyDiv: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontWeight: 600,
      fontSize: 18,
      fontFamily: "Montserrat, sans-serif",
      "& button": {
        padding: 0,
      },
      "@media (max-width: 768px)": {
        fontSize: 16,
      },
    },
    addIcon: {
      color: "#77bbff",
    },
    removeIcon: {
      color: "#e7e7e7",
    },
    checkBox: {
      color: "#77bbff!important",
    },

    helpIcon: {
      fontSize: "20px !important",
      border: "1px solid #e7e7e7",
      borderRadius: "20px",
      width: "26px",
      color: "#77bbff",
      "@media (max-width: 768px)": {
        fontSize: "12px !important",
        border: "1px solid #e7e7e7",
        borderRadius: "12px",
        width: "16px",
      },
    },

    steps: {
      width: "50%",
      height: "100%",
      margin: "0 auto",
      display: "flex",
      justifyContent: "center",
      position: "relative",
      "@media (max-width: 768px)": {
        width: "100% !important",
        animation: "none!important",
        left: "0%!important",
      },
    },

    animation: {
      animation: "pulse 1s ease 1s 3",
    },

    explain: {
      width: "100%",
      fontSize: 13,
      fontWeight: 200,
      fontFamily: "Montserrat, sans-serif",
      "@media (max-width: 768px)": {
        fontSize: 11,
      },
    },
  }));

  const classes = useStyles();

  const [modal, setModal] = useState(false);

  const faqs = [
    {
      question: "What full motion bracket does?",
      answer: "It tilts up and down, swivels",
    },
    {
      question: "What tilting bracket does? ",
      answer: "It tilts up and down. ",
    },
    {
      question: "Do you have fixed brackets?",
      answer: "Tilting bracket can easily become fixed.",
    },

    {
      question: "Do you install Pull Down Mantel brackets?",
      answer: "No, we do not",
    },

    {
      question: "Why there are two prices $75 and $110?",
      answer:
        "If your TV is very heavy, we recommend choosing $110 bracket. Yet, for now it’s $75.",
    },
  ];

  const Brackets = [
    {
      id: 1,
      type: "Full Motion",
      price: 40,
      sub: "tilts up and down, swivels",
      tvSize: 1,
    },
    { id: 2, type: "Tilt", price: 30, sub: "tilts up and down", tvSize: 1 },
    {
      id: 3,
      type: "Full Motion",
      price: 65,
      sub: "tilts up and down, swivels",
      tvSize: 2,
    },
    { id: 4, type: "Tilt", price: 40, sub: "tilts up and down", tvSize: 2 },
    {
      id: 5,
      type: "Full Motion",
      price: 75,
      altPrice: 110,
      sub: "tilts up and down, swivels",
      tvSize: 3,
    },
    { id: 6, type: "Tilt", price: 50, sub: "tilts up and down", tvSize: 3 },
    { id: 7, type: "I have my own bracket", price: 0 },
  ];

  const selectedSizes = tvSizes.filter((tv) => tv.qty !== 0);
  const currentSize = selectedSizes[currentBracket - 1];

  const currentBrackets = brackets.filter(
    (bracket) => bracket.tvid === currentSize.id
  );

  // Calculating total no. of brackets selected for the current Size
  let bracketQty = 0;

  for (var qty = 0; qty < currentBrackets.length; qty++) {
    bracketQty += currentBrackets[qty].qty;
  }

  // Enable next button if no. of selected brackets is equal to no. of selected TVs
  useEffect(() => {
    if (bracketQty === currentSize.qty && !next) {
      toggleNext(true);
    } else if (bracketQty !== currentSize.qty && next) {
      toggleNext(false);
    }
  });
  setTimeout(() => setTime(true), 500);
  const [timeout, setTime] = useState(false);

  /*   const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "spring-popper" : undefined;

  const handleClose = (event) => {
    setAnchorEl(null);
  }; */

  return (
    <div style={{ left: "-10%" }} key={currentSize.id}>
      <Title
        name={
          currentSize.qty > 1 ? (
            <>
              Choose tv wall brackets for {numWords(currentSize.qty)} TVs of{" "}
              <span className={selectedSizes.length > 1 && classes.animation}>
                <b>{currentSize.size} </b>
              </span>
            </>
          ) : (
            <>
              Choose tv wall brackets for{" "}
              <span className={selectedSizes.length > 1 && classes.animation}>
                <b>{currentSize.size} </b>
              </span>
            </>
          )
        }
        step="2"
        key={currentSize.size}
      />

      <div className={classes.body}>
        <div className={classes.sizeHeader1} id="sizeHeader">
          <Grid container justify="space-between" alignItems="center" xs={12}>
            <Grid key="1" item>
              {" "}
            </Grid>
            <Grid key="1" item xs={6} style={{ color: "#000" }}>
              TYPE OF BRACKET{" "}
              {bracketQty > 0 && currentSize.qty > 1 && " / QTY"}
            </Grid>
            <Grid
              key="2"
              item
              xs={5.5}
              md={3}
              style={{ color: "#a2a2a2", cursor: "pointer" }}
              onClick={() => setModal(true)}
            >
              <QuestionAnswerIcon style={{ verticalAlign: "middle" }} /> HAVE A
              QUESTION?
              {/* {bracketQty > 0 && currentSize.qty > 1 && "QTY"} */}
            </Grid>
          </Grid>
        </div>

        {Brackets.filter(
          (bracket) => currentSize.id === bracket.tvSize || bracket.id === 7
        ).map((bracket, index) => (
          <Grow
            in={timeout}
            style={{ transformOrigin: "0 0 0" }}
            {...{ timeout: 500 * (index + 1) }}
          >
            <Paper
              elevation={0}
              className={classes.root1}
              onClick={(e) =>
                (bracketQty < currentSize.qty ||
                  !e.target.checked ||
                  currentSize.qty === 1) &&
                toggleBracket(
                  currentSize.id,
                  bracket,
                  brackets.find(
                    (storeBracket) =>
                      storeBracket.tvid === currentSize.id &&
                      bracket.id === storeBracket.id
                  )
                    ? false
                    : true
                )
              }
            >
              <div className={classes.baseDiv}>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  xs={12}
                >
                  <Grid key="1" item alignItems="center">
                    <Checkbox
                      className={classes.checkBox}
                      checked={
                        brackets.find(
                          (storeBracket) =>
                            storeBracket.tvid === currentSize.id &&
                            bracket.id === storeBracket.id
                        )
                          ? true
                          : false
                      }
                      /*    onChange={(e) =>
                        (bracketQty < currentSize.qty ||
                          !e.target.checked ||
                          currentSize.qty === 1) &&
                        toggleBracket(currentSize.id, bracket, e.target.checked)
                      } */
                    />
                  </Grid>

                  <Grid key="1" item xs={7} md={7} alignItems="center">
                    <div className={classes.nameDiv}>
                      {bracket.type}
                      <br />
                      {bracket.id !== 7 && (
                        <>
                          (+${bracket.price}{" "}
                          {bracket.altPrice && "or $" + bracket.altPrice})
                        </>
                      )}
                    </div>
                    {/*   {bracket.sub && (
                      <div className={classes.explain}>({bracket.sub}) </div>
                    )} */}
                  </Grid>

                  {/*        {bracket.id !== 3 && (
                    <Grid key="2" item xs={1}>
                      <IconButton
                        aria-describedby={id}
                        aria-label="help"
                        className={classes.helpIcon}
                        onClick={handleClick}
                      >
                        ?
                      </IconButton>

                      <Popper
                        id={id}
                        placement="right-start"
                        disablePortal={false}
                        onClose={handleClose}
                        modifiers={{
                          flip: {
                            enabled: true,
                          },
                          preventOverflow: {
                            enabled: true,
                            boundariesElement: "scrollParent",
                          },
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        transition
                      >
                        <PopperCard handleClose={handleClose} />
                      </Popper>
                    </Grid>
                  )} */}
                  <Grid key="3" item xs={3} justify="space-between">
                    {/* Display Quantity + - icons if Qty of TVs for current size is more than one 1 else display checkboxes */}

                    {brackets.find(
                      (storeBracket) =>
                        storeBracket.tvid === currentSize.id &&
                        storeBracket.id === bracket.id
                    )?.qty > 0 &&
                      currentSize.qty > 1 && (
                        <div
                          className={classes.qtyDiv}
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        >
                          <IconButton
                            aria-label="decrease"
                            onClick={(e) => {
                              removeBracket(currentSize.id, bracket);
                              //   e.stopPropagation();
                            }}
                          >
                            <RemoveCircleOutlineIcon
                              className={classes.removeIcon}
                            />
                          </IconButton>
                          <span>
                            {brackets.find(
                              (storeBracket) =>
                                storeBracket.tvid === currentSize.id &&
                                storeBracket.id === bracket.id
                            ) !== undefined
                              ? brackets.find(
                                  (storeBracket) =>
                                    storeBracket.tvid === currentSize.id &&
                                    storeBracket.id === bracket.id
                                ).qty
                              : 0}
                          </span>
                          <IconButton
                            aria-label="increase"
                            onClick={(e) => {
                              bracketQty < currentSize.qty &&
                                addBracket(currentSize.id, bracket);
                              //   e.stopPropagation();
                            }}
                          >
                            <AddCircleOutlineIcon className={classes.addIcon} />
                          </IconButton>
                        </div>
                      )}
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Grow>
        ))}
      </div>

      {modal && (
        <FaqModal
          modal={modal}
          setModal={setModal}
          faqs={faqs}
          title="Bracket"
        />
      )}
    </div>
  );
};

Bracket.propTypes = {
  toggleNext: PropTypes.func.isRequired,
  addBracket: PropTypes.func.isRequired,
  removeBracket: PropTypes.func.isRequired,
  toggleBracket: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  tvSizes: state.order.tvs,
  brackets: state.order.brackets,
  currentBracket: state.step.currentBracket,
  next: state.step.next,
});

export default connect(mapStateToProps, {
  toggleNext,
  addBracket,
  removeBracket,
  toggleBracket,
})(Bracket);
