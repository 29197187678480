import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { addDate, removeDate, setDateIndex } from "../../actions/booking";

const MonthCarousel = ({
  addDate,
  removeDate,
  selectedDates,
  setDateIndex,
  dateIndex,
}) => {
  const useStyles = makeStyles(() => ({
    carouselDiv: {
      display: "flex",
      justifyContent: "center",
    },

    timeDiv: {
      width: "25%",
      float: "left",
      display: "flex",
      justifyContent: "center",
      marginTop: 10,
    },
    bookingTime: {
      width: "90%",
      border: "2px solid #ebebeb",
      textAlign: "center",
      padding: 0,
      fontSize: 12,
      borderRadius: 5,
      fontWeight: 500,
      cursor: "pointer",
      // '&:hover': {
      // 	borderColor: '#77bbff',
      // 	color: '#77bbff',
      // },
    },
    dateBlock: {
      fontSize: 9,
      fontWeight: 600,
      padding: "6px 4px",
    },
  }));

  const classes = useStyles();

  const datePages = [0, 1, 2, 3, 4, 5];
  const dates = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  const milisecPerDay = 24 * 60 * 60 * 1000;

  const handleDate = (date, datePage) => {
    const formattedDate = moment(
      new Date().getTime() +
        datePage * dates.length * milisecPerDay +
        milisecPerDay * date
    ).format("DDMMYYYY");

    setDateIndex(datePage);
    if (
      !selectedDates.find((selectedDate) => selectedDate.date === formattedDate)
    ) {
      if (selectedDates[selectedDates.length - 1]?.times.length === 0) {
        removeDate(selectedDates[selectedDates.length - 1].date);
      }
      addDate(formattedDate);
    } else {
      removeDate(formattedDate);
    }
  };

  return (
    <div className={classes.carouselDiv}>
      <div className="upcomming-event-section" style={{ width: "70%" }}>
        <OwlCarousel
          className="upcomming-event-carousel owl-carousel owl-theme"
          loop
          nav
          items={1}
          dots={false}
          center={true}
          smartSpeed={1000}
          key={`${selectedDates[selectedDates.length - 1]?.date}_${
            selectedDates.length > 0 &&
            selectedDates[selectedDates.length - 1].times.length
          }`}
          startPosition={dateIndex}
        >
          {datePages.map((datePage) => (
            <div>
              {dates.map((date) => (
                <div className={classes.timeDiv}>
                  <div
                    className={classes.bookingTime}
                    onClick={(e) => handleDate(date, datePage)}
                    // If choose booking day, active as blue.
                    style={
                      selectedDates.find(
                        (selectedDate) =>
                          selectedDate.date ===
                          moment(
                            new Date().getTime() +
                              datePage * dates.length * milisecPerDay +
                              milisecPerDay * date
                          ).format("DDMMYYYY")
                      )
                        ? {
                            borderColor: "#77bbff",
                            color: "#77bbff",
                          }
                        : {}
                    }
                  >
                    <div className={classes.dateBlock}>
                      {moment(
                        new Date().getTime() +
                          datePage * dates.length * milisecPerDay +
                          milisecPerDay * date
                      ).format("ddd")}
                      <br />
                      {moment(
                        new Date().getTime() +
                          datePage * dates.length * milisecPerDay +
                          milisecPerDay * date
                      ).format("MMM DD")}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

MonthCarousel.propTypes = {
  addDate: PropTypes.func.isRequired,
  removeDate: PropTypes.func.isRequired,
  selectedDates: PropTypes.array.isRequired,
  setDateIndex: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  selectedDates: state.booking.selectedDates,
  dateIndex: state.booking.dateIndex,
});

export default connect(mapStateToProps, {
  addDate,
  removeDate,
  setDateIndex,
})(MonthCarousel);
