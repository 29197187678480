import React from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

const FaqModal = ({ modal, setModal, faqs, title }) => {
  const useStyles = makeStyles(() => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& div:focus": {
        outline: "none",
      },
    },

    paper: {
      backgroundColor: "white",
      border: 0,
      boxShadow: 5,
      paddingTop: "20px",
      width: "40%",
      display: "flex",
      //  alignItems: "center",
      flexFlow: "column",
      //justifyContent: "space-between",
      minHeight: 150,
      borderRadius: 20,
      "@media (max-width: 768px)": {
        width: "90%",
      },
    },

    modalHeading: {
      paddingLeft: "15px",
      fontWeight: "bold",
      fontSize: "14px",
    },

    close: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      marginRight: 22,
      "& button": {
        padding: 0,
        "& svg": {
          fontSize: "2rem",
        },
      },
    },

    faqs: {
      marginTop: 0,
    },

    question: {
      paddingLeft: "15px",
      paddingRight: "15px",
      fontSize: "12px",
      fontWeight: "bold",
      marginTop: "10px",
    },

    answer: {
      paddingLeft: "15px",
      paddingRight: "15px",
      fontSize: "12px",
      marginBottom: "10px",
    },
  }));
  const classes = useStyles();
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              xs={12}
              md={12}
              style={{
                borderBottom: "0.1em solid #DCDCDC",
                paddingBottom: "0.5em",
              }}
            >
              <Grid key="1" item className={classes.modalHeading}>
                {title}
              </Grid>
              <Grid key="2" item>
                <div className={classes.close}>
                  <IconButton
                    aria-label="close"
                    onClick={(e) => {
                      setModal(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>

            {faqs.map((faq) => (
              <div className={classes.faqs}>
                <div className={classes.question}>{faq.question}</div>
                <div className={classes.answer}>{faq.answer}</div>
              </div>
            ))}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default FaqModal;
