import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },

  palette: {
    primary: {
      main: "#77bbff",
    },
  },
});

export default theme;
