import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Title from "./layout/Title";
import FaqModal from "./layout/FaqModal";

// Actions
import { toggleTv, addTv, removeTv, updateLift } from "../actions/order";
import { toggleNext } from "../actions/step";

// Material UI
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Grow from "@material-ui/core/Grow";
import Grid from "@material-ui/core/Grid";
import { Checkbox, FormControl } from "@material-ui/core";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

const TvSize = ({
  tvSizes,
  tvQty,
  toggleTv,
  addTv,
  removeTv,
  updateLift,
  next,
  toggleNext,
}) => {
  const useStyles = makeStyles(() => ({
    body: {
      paddingTop: 36,
      minHeight: 787,
      fontFamily: "Montserrat, sans-serif",
      "@media (max-width: 768px)": {
        paddingTop: 24,
        minHeight: 651,
      },
    },

    sizeHeader1: {
      display: "flex",
      padding: "45px 20px 2px 20px",
      marginBottom: -20,
      fontSize: 12,
      color: "#b7b7b7",
      fontWeight: 500,
      letterSpacing: "0.5px",
      lineHeight: 3,
      left: 0,
      opacity: 1,
      position: "relative",
      "@media (max-width: 768px)": {
        padding: "25px 20px 2px 20px",
        animation: "none!important",
        fontSize: 10,
      },
    },
    nameSide: {
      width: "70%",
      marginLeft: "15%",
      color: "#000",
      "@media (max-width: 768px)": {
        width: "40%",
      },
    },
    qtySide: {
      width: "30%",
      textAlign: "center",
      color: "#a2a2a2",
      cursor: "pointer",

      "@media (max-width: 768px)": {
        width: "45%",
      },
    },
    root1: {
      display: "flex",
      //flexFlow: "column",
      borderRadius: 20,
      padding: 17.5,
      backgroundColor: "#fff",
      margin: "26px 0px",
      left: 0,
      position: "relative",
      boxShadow: "0 2px 6px 0 rgba(0, 0, 0, .1)",
      border: "1px solid rgba(218,220,228,.1)",
      minHeight: "50px",
      "@media (max-width: 768px)": {
        borderRadius: 10,
        animation: "none!important",
        boxShadow: "none",
        border: "none",
      },
    },

    nameDiv: {
      width: "100%",
      fontWeight: 500,
      fontSize: 17,
      fontFamily: "Montserrat, sans-serif",
      color: "#000",
      "@media (max-width: 768px)": {
        fontSize: 14,
      },
    },
    qtyDiv: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontWeight: 600,
      fontSize: 18,
      fontFamily: "Montserrat, sans-serif",
      "& button": {
        padding: 0,
      },
      "@media (max-width: 768px)": {
        fontSize: 16,
      },
    },
    midLine: {
      position: "relative",
      borderBottom: "1px solid #f0eff4",
      margin: "15px -20px",
    },
    triangle: {
      width: 0,
      height: 0,
      borderLeft: "12px solid transparent",
      borderRight: "12px solid transparent",
      borderBottom: "12px solid #C2F0ED",
      position: "absolute",
      right: "13%",
      marginTop: -12,
      "@media (max-width: 768px)": {
        borderWidth: 8,
        marginTop: -8,
        marginRight: 5,
      },
    },
    addDiv: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& div:nth-child(1)": {
        width: "55%",
        fontSize: 16,
        fontWeight: 500,
        fontFamily: "Montserrat, sans-serif",
        "@media (max-width: 768px)": {
          fontSize: 12,
        },
      },
      "& div:nth-child(2)": {
        width: "45%",
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    btn: {
      width: "45%",
      border: "2px solid #e7e7e7",
      borderRadius: 10,
      marginLeft: 20,
      fontSize: 16,
      color: "#000",
      fontWeight: 500,
      "@media (max-width: 768px)": {
        fontSize: 12,
        minWidth: 20,
        borderRadius: 5,
        marginLeft: 8,
      },
    },
    addIcon: {
      color: "#77bbff",
    },
    removeIcon: {
      color: "#e7e7e7",
    },
  }));

  // Enable Next Button once TV at-least 1 tv is selected
  useEffect(() => {
    if (
      !next &&
      ((tvQty > 0 && tvSizes[2].qty < 1) ||
        (tvSizes[2].qty > 0 && tvSizes[2].lift !== "ask"))
    ) {
      toggleNext(true);
    } else if (
      next &&
      (tvQty === 0 ||
        (tvQty > 0 && tvSizes[2].qty > 0 && tvSizes[2].lift === "ask"))
    ) {
      toggleNext(false);
    }
  });

  setTimeout(() => setTime(true), 500);
  const [timeout, setTime] = useState(false);

  const classes = useStyles();

  const [modal, setModal] = useState(false);

  const faqs = [
    {
      question: "How do i know the size of TV?",
      answer: "Tv size is measured diagonally",
    },
  ];

  return (
    <div>
      <Title
        name={"How big is your TV?"}
        name2={tvQty > 0 && "Do you have more than one TV?"}
        fade={tvQty > 0 ? true : false}
        step="1"
      />

      <div className={classes.body}>
        <div className={classes.sizeHeader1} id="sizeHeader">
          <div className={classes.nameSide}>SIZE {tvQty > 0 && "/ QTY"}</div>
          <div className={classes.qtySide} onClick={() => setModal(true)}>
            <QuestionAnswerIcon style={{ verticalAlign: "middle" }} /> HAVE A
            QUESTION?
          </div>
          {/*  {tvQty > 0 && <div className={classes.qtySide}>QTY</div>} */}
        </div>
        {tvSizes.map((tvSize) => (
          <Grow
            in={timeout}
            style={{ transformOrigin: "0 0 0" }}
            {...{ timeout: 500 * tvSize.id }}
          >
            <Paper
              className={classes.root1}
              onClick={(e) =>
                toggleTv(tvSize.id, tvSize.qty > 0 ? false : true)
              }
            >
              <Grid
                container
                justify="space-between"
                alignItems="center"
                xs={12}
                md={12}
              >
                <Grid key="1" item>
                  <div className={classes.radios}>
                    <FormControl
                      component="fieldset"
                      fullWidth="true"
                      size="small"
                    >
                      <Checkbox
                        checked={tvSize.qty > 0 ? true : false}
                        color="primary"
                        name="checkedB"
                        inputProps={{ "aria-label": "primary checkbox" }}
                        className={classes.switch}
                      />
                    </FormControl>
                  </div>
                </Grid>

                <Grid key="2" item xs={7} md={7}>
                  <div className={classes.nameDiv}>
                    {tvSize.size} <br /> (${tvSize.price})
                  </div>
                </Grid>

                <Grid key="3" item xs={3} md={3}>
                  {tvSize.qty > 0 && (
                    <div
                      className={classes.qtyDiv}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <IconButton
                        aria-label="decrease"
                        onClick={() => {
                          removeTv(tvSize.id, tvSize.price);
                        }}
                      >
                        <RemoveCircleOutlineIcon
                          className={classes.removeIcon}
                        />
                      </IconButton>
                      <span>{tvSize.qty}</span>
                      <IconButton
                        aria-label="increase"
                        onClick={() => {
                          addTv(tvSize.id, tvSize.size, tvSize.price);
                        }}
                      >
                        <AddCircleOutlineIcon className={classes.addIcon} />
                      </IconButton>
                    </div>
                  )}
                </Grid>

                {/* Display help prompt for Sizes where required */}
                {tvSize.qty > 0 && tvSize.lift === "ask" && (
                  <Grid key="4" item xs={12} md={12}>
                    <div className={classes.midLine}>
                      <div className={classes.triangle}></div>
                    </div>
                    <div className={classes.addDiv}>
                      <div>Will you help lift the TV?</div>
                      <div>
                        <Button
                          className={classes.btn}
                          onClick={(e) => {
                            updateLift(tvSize.id, false);
                            e.stopPropagation();
                          }}
                        >
                          No (+$
                          {tvSize.qty * (tvSize.noLiftPrice - tvSize.liftPrice)}
                          )
                        </Button>
                        <Button
                          className={classes.btn}
                          onClick={(e) => {
                            updateLift(tvSize.id, true);
                            e.stopPropagation();
                          }}
                        >
                          Yes
                        </Button>
                      </div>
                    </div>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grow>
        ))}
      </div>
      {modal && (
        <FaqModal
          modal={modal}
          setModal={setModal}
          faqs={faqs}
          title="TV Size"
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  tvSizes: state.order.tvs,
  tvQty: state.order.tvQty,
  next: state.step.next,
});

TvSize.propTypes = {
  addTv: PropTypes.func.isRequired,
  removeTv: PropTypes.func.isRequired,
  updateLift: PropTypes.func.isRequired,
  toggleNext: PropTypes.func.isRequired,
  toggleTv: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  addTv,
  removeTv,
  updateLift,
  toggleNext,
  toggleTv,
})(TvSize);
