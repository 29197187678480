import {
  NEXT_STEP,
  PREV_STEP,
  TOGGLE_NEXT,
  EDIT_STEP,
  SET_DRAWER,
  NEXT_BRACKET,
  PREV_BRACKET,
} from "./types";

export const nextStep = () => (dispatch) => {
  dispatch({
    type: NEXT_STEP,
    payload: {},
  });
};

export const prevStep = () => (dispatch) => {
  dispatch({
    type: PREV_STEP,
    payload: {},
  });
};

export const toggleNext = (next) => (dispatch) => {
  dispatch({
    type: TOGGLE_NEXT,
    payload: { next },
  });
};

export const editStep = (step) => (dispatch) => {
  dispatch({
    type: EDIT_STEP,
    payload: { step },
  });
};

export const setDrawer = (drawer) => (dispatch) => {
  dispatch({
    type: SET_DRAWER,
    payload: drawer,
  });
};

export const nextBracket = () => (dispatch) => {
  dispatch({
    type: NEXT_BRACKET,
    payload: {},
  });
};

export const prevBracket = () => (dispatch) => {
  dispatch({
    type: PREV_BRACKET,
    payload: {},
  });
};
