import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import moment from "moment";

const useStyles = makeStyles({
  fullList: {
    width: "auto",
    "@media (max-width: 1024px)": {
      display: "none",
    },
  },
  detailPaper: {
    padding: 20,
    height: "calc( 100Vh - 20px )",
    overflowY: "auto",
    backgroundColor: "#f0eff4",
    "@media (max-width: 1137px)": {
      height: "calc( 100Vh - 130px )",
    },
  },

  list: {
    width: 250,
  },
  orderDetail: {
    boxShadow: "0 5px 20px 0 rgba(0, 0, 0, .1)",
    padding: "1px 10px 26px 10px",
    borderRadius: 10,
    "& h1": {
      fontSize: 24,
      fontWeight: 500,
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.08,
      letterSpacing: -0.29,
      color: "#46515b;",
      margin: "25px 0",
    },
    "& h2": {
      fontSize: 18,
      marginBottom: 0,
      fontWeight: "500!important",
      fontStyle: "normal",
      fontstretch: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      color: "#46515b;",
    },
  },
  servicesAggregationDetailsWrapper: {
    "& h3": {
      margin: "2px 0px",
      fontSize: 16,
      opacity: 0.9,
      color: "#46515b",
    },
    // borderTop: '1px dashed #ccc',
    marginTop: 15,
    paddingTop: 5,
  },
  servicesAggregationDetails: {
    "& div": {
      fontSize: 16,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontWeight: 400,
      color: "#46515b",
    },
  },
  serviceType: {
    marginTop: 15,
    fontSize: 16,
    opacity: 0.9,
    color: "#46515b",
  },
  serviceAddictionalPrice: {
    marginTop: 7,
    fontSize: 16,
    fontWeight: 700,
    color: "#46515b",
  },
  deviceProperties: {
    fontSize: 16,
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#46515b",
  },
  servicePrice: {
    fontWeight: 700,
    color: "#46515b",
  },
  extraServiceName: {
    marginTop: 7,
    fontSize: 16,
    color: "#46515b",
  },
  serviceName: {
    "& p": {
      margin: "5px 0",
    },
  },
  myCartTotalPrice: {
    paddingTop: 5,
    marginTop: 5,
    // borderTop: '1px solid #ccc',
    color: "#46515b",
    "&>span": {
      fontWeight: 500,
      fontSize: 18,
    },
  },
  noPayments: {
    width: "100%",
    margin: " 7px 0px",
    padding: "10px 0px",
    borderRadius: 10,
    backgroundColor: "#77ddff4d",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "center",
    color: "#768141",
  },
  requestSummeryRowsContainer: {
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.63,
    letterSpacing: "normal",
    color: "#030303",
    marginBottom: 20,
  },
  userDetailsWrapper: {
    marginTop: 20,
    padding: "5px 10px",
    boxShadow: "0 5px 20px 0 rgba(0, 0, 0, .1)",
    borderRadius: 10,
  },
  requestSummeryRowHeader: {
    fontSize: 16,
    fontWeight: 500,
    fontStyle: "normal",
    fontstretch: "normal",
    lineHeight: 1.29,
    letterSpacing: 0.1,
    color: "#000",
    "& h1": {
      fontSize: 18,
      fontWeight: "500!important",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.08,
      letterSpacing: -0.29,
      color: "#46515b;",
      margin: "25px 0",
    },
  },
  dayTime: {
    color: "#46515b",
    fontSize: 16,
    marginBottom: 10,
    margin: "8px 0 0",
    fontWeight: 400,
  },
  right: {
    position: "fixed",
    top: 10,
    width: "20%",
    "@media (max-width: 1137px)": {
      width: "25%",
    },
    "@media (max-width: 1024px)": {
      display: "none!important",
    },
  },
});

const OrderDetails = ({
  tvSizes,
  brackets,
  orderTotal,
  discount,
  options,
  optionsSubtotal,
  addOns,
  addOnsSubtotal,
  currentStep,
  selectedDates,
  timeSlots,
  bookingCharges,
}) => {
  const classes = useStyles();
  const selectedSizes = tvSizes.filter((tv) => tv.qty !== 0);
  const selectedOptions = options.filter((option) => option.selected === true);
  const selectedAddOns = addOns.filter((addOn) => addOn.selected === true);

  return (
    <div className={classes.right} style={{ right: 10 }}>
      <div className={classes.fullList} role="presentation">
        <Paper elevation={0} className={classes.detailPaper}>
          <div className={classes.orderDetail}>
            <h1>Your order details</h1>
            <h2>
              <b>TV Mounting</b>
            </h2>

            {selectedSizes.map((size) => (
              <div
                className={classes.servicesAggregationDetailsWrapper}
                key={size.id}
              >
                <h3>
                  <b>{size.size}</b>
                </h3>
                <div className={classes.servicesAggregationDetails}>
                  <div className={classes.aggregateService}>
                    <span className={classes.serviceName}>
                      TV Mounting ({size.qty} tv)
                      {size.id === 3 &&
                        (size.lift === true ? (
                          <p>Will you help lift the TV? Yes.</p>
                        ) : (
                          <p>Will you help lift the TV? No.</p>
                        ))}
                    </span>
                    <span className={classes.servicePrice}>
                      ${size.subtotal}
                    </span>
                  </div>
                </div>
                {brackets.filter((bracket) => bracket.tvid === size.id).length >
                0 ? (
                  <div>
                    <div>
                      <b className={classes.serviceType}>Bracket Type</b>
                    </div>{" "}
                    {brackets
                      .filter((bracket) => bracket.tvid === size.id)
                      .map((bracket) => (
                        <div className={classes.deviceProperties}>
                          <span className={classes.serviceName}>
                            {bracket.type} ({bracket.qty})
                            {/* {"(" + size.bracket.qty + ")"} */}
                          </span>

                          <span className={classes.servicePrice}>
                            ${bracket.price * bracket.qty}
                          </span>
                        </div>
                      ))}
                  </div>
                ) : (
                  ""
                )}

                <hr />
              </div>
            ))}

            {selectedOptions.length > 0 ? (
              <>
                <div>
                  <div>
                    <b className={classes.serviceType}>Extra Charges</b>
                  </div>
                  <div className={classes.deviceProperties}>
                    <div>
                      {selectedOptions.map((option) => (
                        <div className={classes.extraServiceName}>
                          {option.display} ({option.qty})
                        </div>
                      ))}
                    </div>
                    <span className={classes.serviceAddictionalPrice}>
                      ${optionsSubtotal}
                    </span>
                  </div>
                  <hr />
                </div>
              </>
            ) : (
              ""
            )}

            {selectedAddOns.length > 0 ? (
              <>
                <div>
                  <div>
                    <b className={classes.serviceType}>Add Ons</b>
                  </div>
                  <div className={classes.deviceProperties}>
                    <div>
                      {selectedAddOns.map((addOn) => (
                        <div className={classes.extraServiceName}>
                          {addOn.name} ({addOn.qty})
                        </div>
                      ))}
                    </div>
                    <span className={classes.serviceAddictionalPrice}>
                      ${addOnsSubtotal}
                    </span>
                  </div>
                  <hr />
                </div>
              </>
            ) : (
              ""
            )}

            {bookingCharges > 0 ? (
              <>
                <div>
                  <div>
                    <b className={classes.serviceType}>Booking Charges</b>
                  </div>
                  <div className={classes.deviceProperties}>
                    <div>
                      <div className={classes.extraServiceName}>
                        Same Day Installation
                      </div>
                    </div>
                    <span className={classes.serviceAddictionalPrice}>
                      ${bookingCharges}
                    </span>
                  </div>
                  <hr />
                </div>
              </>
            ) : (
              ""
            )}

            <div className={classes.myCartTotalPrice}>
              {discount !== 0 && (
                <div>
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: 18,
                    }}
                  >
                    Discount
                  </span>
                  <span style={{ float: "right" }}>
                    <b>${discount}</b>
                  </span>
                </div>
              )}

              <div>
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                  Total
                </span>
                <span style={{ float: "right" }}>
                  <b>${orderTotal + bookingCharges - discount}</b>
                </span>
              </div>
            </div>
          </div>

          {currentStep === 6 && selectedDates.length !== 0 && (
            <div className={classes.userDetailsWrapper}>
              <div className={classes.requestSummeryWrapper}>
                <div className={classes.requestSummeryRow}>
                  {/*  time, day data*/}

                  <div className={classes.requestSummeryRowHeader}>
                    <h1>Requested Time</h1>
                  </div>

                  <div className={classes.requestSummeryRowsContainer}>
                    {selectedDates.map((selectedDate, index) => (
                      <div key={index} className={classes.dayTime}>
                        {moment(selectedDate.date, "DDMMYY").format(
                          "MMM Do YYYY"
                        )}
                        <br />
                        {selectedDate.times.map((time, index) => (
                          <div key={index}>
                            {
                              timeSlots.find((timeSlot) => timeSlot.id === time)
                                .time
                            }
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
};
// map props to state
const mapStateToProps = (state) => ({
  brackets: state.order.brackets,
  tvSizes: state.order.tvs,
  orderTotal: state.order.total,
  discount: state.order.discount,
  options: state.order.options,
  optionsSubtotal: state.order.optionsSubtotal,
  addOns: state.order.addOns,
  addOnsSubtotal: state.order.addOnsSubtotal,
  currentStep: state.step.current,
  selectedDates: state.booking.selectedDates,
  timeSlots: state.booking.timeSlots,
  bookingCharges: state.booking.bookingCharges,
});

// connect to redux
export default connect(mapStateToProps, {})(OrderDetails);
