import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import moment from "moment";
import { setDrawer } from "../actions/step";

const OrderDetailsMobile = ({
  tvSizes,
  brackets,
  optionsSubtotal,
  orderTotal,
  currentStep,
  selectedDates,
  timeSlots,
  addOnsSubtotal,
  discount,
  setDrawer,
  bookingCharges,
}) => {
  const useStyles = makeStyles({
    detailPaper: {
      padding: 20,
    },
    closeButtonDiv: {
      position: "fixed",
      width: "100%",
      backgroundColor: "#fff",
      zIndex: 1111,
      borderTopRightRadius: "25px!important",
      borderTopLeftRadius: "25px!important",
    },
    closeButton: {
      position: "relative",
      fontSize: 32,
      cursor: "pointer",
      marginLeft: 15,
    },
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
    orderDetail: {
      marginTop: "10%",
      position: "relative",
      "& h1": {
        fontSize: 24,
        fontWeight: 500,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: 1.08,
        letterSpacing: -0.29,
        color: "#46475c",
        marginTop: 0,
        marginBottom: 25,
      },
      "& h2": {
        fontSize: 18,
        marginBottom: 0,
        fontWeight: 400,
        fontStyle: "normal",
        fontstretch: "normal",
        lineHeight: 1.33,
        letterSpacing: "normal",
        color: "#46475c",
      },
    },
    servicesAggregationDetailsWrapper: {
      "& h3": {
        margin: "2px 0px",
        fontSize: 16,
      },
      // borderTop: '1px dashed #ccc',
      marginTop: 5,
      paddingTop: 5,
    },
    servicesAggregationDetails: {
      "& div": {
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontWeight: 400,
      },
    },
    serviceType: {
      fontSize: 14,
      opacity: 0.9,
    },
    deviceProperties: {
      fontSize: 12,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    serviceName: {
      "& p": {
        margin: "5px 0",
      },
    },
    myCartTotalPrice: {
      paddingTop: 5,
      marginTop: 5,
      borderTop: "1px solid #ccc",
      "& span": {
        fontSize: 18,
      },
    },
    noPayments: {
      width: "100%",
      margin: " 7px 0px",
      padding: "10px 0px",
      borderRadius: 10,
      backgroundColor: "#f0eff4",
      fontSize: 15,
      fontWeight: 400,
      textAlign: "center",
      // color: "#768141",
      color: "#3C99FF",
    },
    requestSummeryRowHeader: {
      fontSize: 12,
      fontWeight: 500,
      fontStyle: "normal",
      fontstretch: "normal",
      lineHeight: 1.29,
      letterSpacing: 0.1,
      color: "#000",
    },
    requestSummeryRowsContainer: {
      fontSize: 12,
      fontWeight: 400,
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.63,
      letterspacing: "normal",
      color: "#46515b",
      marginBottom: 20,
    },
  });

  const classes = useStyles();

  const selectedSizes = tvSizes.filter((tv) => tv.qty !== 0);

  return (
    <div className={classes.fullList} role="presentation">
      <div className={classes.closeButtonDiv}>
        <span className={classes.closeButton} onClick={(e) => setDrawer(false)}>
          &#215;
        </span>
      </div>
      <Paper elevation={0} className={classes.detailPaper}>
        <div className={classes.orderDetail}>
          <h1>Your order details</h1>
          <h2>
            <b>TV Mounting</b>
          </h2>
          {/* step 1 data */}
          {selectedSizes.map((size) => (
            <div
              className={classes.servicesAggregationDetailsWrapper}
              key={size.id}
            >
              <h3>
                <b>{size.size}</b>
              </h3>
              <div className={classes.servicesAggregationDetails}>
                <div className={classes.aggregateService}>
                  <span className={classes.serviceName}>
                    TV Mounting ({size.qty} tv)
                    {size.id === 3 &&
                      (size.lift === true ? (
                        <p>Will you help lift the TV? Yes.</p>
                      ) : (
                        size.lift !== "ask" && (
                          <p>Will you help lift the TV? No.</p>
                        )
                      ))}
                  </span>
                  <span className={classes.servicePrice}>
                    ${size.qty * size.price}
                  </span>
                </div>
              </div>
              {/* step 2 data */}
              <div>
                {brackets.filter((bracket) => bracket.tvid === size.id).length >
                  0 && (
                  <div>
                    <b className={classes.serviceType}>Bracket Type</b>
                  </div>
                )}
                {brackets
                  .filter((bracket) => bracket.tvid === size.id)
                  .map((bracket) => (
                    <div className={classes.deviceProperties}>
                      <span className={classes.serviceName}>
                        {bracket.type} ({bracket.qty})
                        {/* {"(" + size.bracket.qty + ")"} */}
                      </span>

                      <span className={classes.servicePrice}>
                        ${bracket.price * bracket.qty}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          ))}
          {/* step 3 data */}
          {optionsSubtotal > 0 && (
            <div className={classes.deviceProperties} style={{ marginTop: 7 }}>
              <b className={classes.serviceType}>Options</b>
              <span className={classes.servicePrice}>${optionsSubtotal}</span>
            </div>
          )}

          {addOnsSubtotal > 0 && (
            <div className={classes.deviceProperties} style={{ marginTop: 7 }}>
              <b className={classes.serviceType}>Add Ons</b>
              <span className={classes.servicePrice}>${addOnsSubtotal}</span>
            </div>
          )}

          {bookingCharges > 0 && (
            <div className={classes.deviceProperties} style={{ marginTop: 7 }}>
              <b className={classes.serviceType}>Same Day Installation</b>
              <span className={classes.servicePrice}>${bookingCharges}</span>
            </div>
          )}

          <div className={classes.myCartTotalPrice}>
            {discount !== 0 && (
              <div>
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                  Discount
                </span>
                <span style={{ float: "right" }}>
                  <b>${discount}</b>
                </span>
              </div>
            )}

            <div>
              <span>Total</span>
              <span style={{ float: "right" }}>
                <b>${orderTotal + bookingCharges - discount}</b>
              </span>
            </div>
            <div className={classes.noPayments}>
              No payment until the job is done! <br />
              We accept Venmo, CashApp, Cash, ApplePay.
            </div>
          </div>
          {/* day, time data */}
          <div className={classes.userDetailsWrapper}>
            <div className={classes.requestSummeryWrapper}>
              <div className={classes.requestSummeryRow}>
                {selectedDates.length !== 0 && (
                  <>
                    <div className={classes.requestSummeryRowHeader}>
                      <b>BOOKING SLOT</b>
                    </div>
                    <div className={classes.requestSummeryRowsContainer}>
                      {selectedDates.map((selectedDate, index) => (
                        <div key={index} style={{ marginBottom: 10 }}>
                          {moment(selectedDate.date, "DDMMYYYY").format(
                            "MMM Do YYYY"
                          )}
                          <br />
                          {selectedDate.times.map((bookingTime, index) => (
                            <div key={index}>
                              {
                                timeSlots.find(
                                  (timeSlot) => timeSlot.id === bookingTime
                                ).time
                              }
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

OrderDetailsMobile.propTypes = {
  setDrawer: PropTypes.func.isRequired,
};

// map props to state
const mapStateToProps = (state) => ({
  brackets: state.order.brackets,
  tvSizes: state.order.tvs,
  orderTotal: state.order.total,
  discount: state.order.discount,
  optionsSubtotal: state.order.optionsSubtotal,
  addOnsSubtotal: state.order.addOnsSubtotal,
  currentStep: state.step.current,
  selectedDates: state.booking.selectedDates,
  timeSlots: state.booking.timeSlots,
  bookingCharges: state.booking.bookingCharges,
});

// connect to redux
export default connect(mapStateToProps, { setDrawer })(OrderDetailsMobile);
