import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { addDate, removeDate } from "../../actions/booking";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const Dates = ({ addDate, removeDate, selectedDates }) => {
  const useStyles = makeStyles(() => ({
    bookingTime: {
      margin: "0 auto",
      marginBottom: 20,
      marginTop: 10,
      width: "90%",
      border: "2px solid #ebebeb",
      // textAlign: "center",
      padding: 8,
      fontSize: 13,
      fontFamily: "Montserrat, sans-serif",
      borderRadius: 20,
      fontWeight: 500,
      cursor: "pointer",
      boxShadow: "0 2px 6px 0 rgba(0, 0, 0, .1)",
      "&:hover": {
        borderColor: "#77bbff",
        color: "#77bbff",
      },
      "@media (max-width: 768px)": {
        fontSize: 12,
        borderRadius: 10,
        padding: "8px 0px",
        boxShadow: "none",
      },
    },
  }));

  const classes = useStyles();

  const dates = [{ day: 0 /*  price: 19 */ }, { day: 1 }, { day: 2 }];

  // checkbox changed
  const handleForward = (e, date) => {
    const formattedDate = moment(today + milisecPerDay * date.day).format(
      "DDMMYYYY"
    );
    setTimeout(() => {
      if (
        !selectedDates.find(
          (selectedDate) => selectedDate.date === formattedDate
        )
      ) {
        if (selectedDates[selectedDates.length - 1]?.times.length === 0) {
          removeDate(selectedDates[selectedDates.length - 1].date);
        }
        addDate(formattedDate, date.price);
      } else {
        removeDate(formattedDate);
      }
    }, 5);
  };

  const milisecPerDay = 24 * 60 * 60 * 1000;
  const today = new Date().getTime();

  return (
    <>
      {dates.map((date) => (
        <>
          <Paper
            className={classes.bookingTime}
            onClick={(e) => handleForward(e, date)}
            style={
              selectedDates.find(
                (selectedDate) =>
                  selectedDate.date ===
                  moment(today + milisecPerDay * date.day).format("DDMMYYYY")
              ) !== undefined
                ? {
                    borderColor: "#77bbff",
                    color: "#77bbffcc",
                  }
                : {
                    borderColor: "#ebebeb",
                    color: "#030303cc",
                  }
            }
          >
            <Grid
              container
              justify="space-between"
              alignItems="center"
              xs={12}
              md={12}
            >
              <Grid key="1" item xs={12} md={12}>
                <div
                  style={{ textAlign: "center", fontSize: 18, fontWeight: 400 }}
                >
                  {" "}
                  {moment(today + milisecPerDay * date.day).format("ddd")}{" "}
                  {moment(today + milisecPerDay * date.day).format("MMM")}{" "}
                  {moment(today + milisecPerDay * date.day).format("DD")}{" "}
                  {date.price && `(+$${date.price})`}
                </div>
              </Grid>
            </Grid>
          </Paper>{" "}
        </>
      ))}
    </>
  );
};

Dates.propTypes = {
  addDate: PropTypes.func.isRequired,
  removeDate: PropTypes.func.isRequired,
  selectedDates: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  selectedDates: state.booking.selectedDates,
});

export default connect(mapStateToProps, {
  addDate,
  removeDate,
})(Dates);
