import React, { useEffect, useState } from "react";
import Title from "./layout/Title";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setUserDetails, setValidateUser } from "../actions/booking";
import { nextStep, toggleNext } from "../actions/step";
import MuiPhoneNumber from "material-ui-phone-number";
import FaqModal from "./layout/FaqModal";

// Material UI
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

const Booking = ({
  userDetails,
  setUserDetails,
  validateUser,
  nextStep,
  setValidateUser,
  next,
  toggleNext,
}) => {
  const useStyles = makeStyles((theme) => ({
    title1: {
      fontWeight: 500,
      color: "#a2a2a2",
      fontSize: 12,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px 10px 0px",
      marginTop: 10,
      left: 0,
      opacity: 1,
      position: "relative",
      "@media (max-width: 768px)": {
        animation: "none!important",
        opacity: 1,
        fontSize: 10,
      },
    },

    form: {
      paddingTop: 36,
      minHeight: 840,
      "@media (max-width: 768px)": {
        paddingTop: 24,
        minHeight: 725,
      },
    },

    inputLabel: {
      color: "black",
      fontSize: 14,
    },

    inputText: {
      borderRadius: 8,
      boxShadow: "0 2px 6px 0 rgba(0, 0, 0, .1)",
      backgroundColor: "#fff",
    },

    asterisk: {
      color: "#77bbff",
    },
  }));

  const classes = useStyles();

  // const [formData, setFormData] = useState(bookingData);

  const {
    name,
    phone,
    phoneVerification,
    address,
    city,
    zipCode,
  } = userDetails;

  const onChange = (e) =>
    setUserDetails({ ...userDetails, [e.target.id]: e.target.value });

  const phoneUpdate = (phone) => {
    setUserDetails({ ...userDetails, phone: phone });
  };

  const phoneVerificationUpdate = (phone) => {
    setUserDetails({ ...userDetails, phoneVerification: phone });
  };

  const [errors, setErrors] = useState({});

  //  form validation
  const validation = () => {
    let count = 0;
    let temp = {};

    if (!name) {
      temp.name = "Required! Please input the Name";
      count++;
    }

    if (!phone) {
      temp.phone = "Required! Please input the Phone Number";
      count++;
    } else if (
      !/^(\+1\s|1|)?((\(\d{3}\))|\d{3})(-|\s)?(\d{3})(-|\s)?(\d{4})$/.test(
        phone
      )
    ) {
      temp.phone = "Invalid Phone Number. Phone Number must be 10 numbers.";
      count++;
    }

    if (!phoneVerification) {
      temp.phoneVerification = "Required! Please input the Phone Verification";
      count++;
    } else if (phone !== phoneVerification) {
      temp.phoneVerification = "Phone Verification must match Phone";
      count++;
    }
    /*     if (!email) {
      temp.email = "Required! Please input the Email";
      count++;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userDetails.email)
    ) {
      temp.email = "Invalid Email Address";
      count++;
    } */
    if (!address) {
      temp.address = "Required! Please input the Address";
      count++;
    }
    if (!city) {
      temp.city = "Required! Please input the City";
      count++;
    }

    if (!zipCode) {
      temp.zipcode = "Required! Please input the Zip Code";
      count++;
    } else if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode)) {
      temp.zipcode = "Please enter a valid Zip Code";
      count++;
    }

    setErrors(temp);

    return count;
  };

  useEffect(() => {
    if (validateUser) {
      if (validation()) {
        setValidateUser(false);
        return;
      }
      setValidateUser(false);
      nextStep();
    }
  });

  useEffect(() => {
    if (!next) {
      toggleNext(true);
    }
  });

  const [personalModal, setPersonalModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);

  const personalFaqs = [
    {
      question: "Why do we ask your name and your phone number?",
      answer:
        "We only require a phone number. Don't worry, we use your phone number to reach out and for updates about your appointment.",
    },
  ];

  const contactFaqs = [
    {
      question: "Why do you need my address?",
      answer: "The address is for technician to arrive and install tv",
    },
  ];

  return (
    <div>
      <Title name={"Booking"} step="5" />
      <form className={classes.form}>
        <div className={classes.title1}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            xs={12}
            md={12}
          >
            <Grid key="1" item>
              PERSONAL INFORMATION
            </Grid>
            <Grid
              key="2"
              item
              style={{ color: "#a2a2a2", cursor: "pointer" }}
              onClick={() => setPersonalModal(true)}
            >
              <QuestionAnswerIcon style={{ verticalAlign: "middle" }} /> HAVE A
              QUESTION?
            </Grid>
          </Grid>
        </div>

        <div style={{ marginBottom: -14 }}>
          <FormControl
            error={errors.name ? true : false}
            variant="outlined"
            fullWidth
            size="small"
            margin="normal"
          >
            <InputLabel
              htmlFor="name"
              classes={{
                root: classes.inputLabel,
                asterisk: classes.asterisk,
              }}
              required
            >
              Full Name
            </InputLabel>
            <OutlinedInput
              id="name"
              value={name}
              onChange={(e) => onChange(e)}
              label="Full Name"
              className={classes.inputText}
              inputProps={{
                style: {
                  textTransform: "capitalize",
                },
              }}
            />

            <FormHelperText error variant="outlined">
              {errors.name}
            </FormHelperText>
          </FormControl>

          <FormControl
            error={errors.phone ? true : false}
            variant="outlined"
            fullWidth
            size="small"
            margin="normal"
          >
            <MuiPhoneNumber
              onlyCountries={["us"]}
              disableCountryCode
              error={errors.phone ? true : false}
              id="phone"
              value={phone}
              defaultCountry={"us"}
              onChange={(phone) => phoneUpdate(phone)}
              color="primary"
              variant="outlined"
              label="Phone"
              inputClass={classes.inputText}
              InputProps={{ error: errors.phone ? true : false }}
              inputProps={{
                placeholder: "xxx-xxx-xxxx",
                autocomplete: "phone",
                form: {
                  autocomplete: "off",
                },
              }}
              disableDropdown
              required
              size="small"
              InputLabelProps={{
                error: errors.phone ? true : false,
                classes: {
                  root: classes.inputLabel,
                  asterisk: classes.asterisk,
                },
              }}
            />

            <FormHelperText error variant="outlined">
              {errors.phone}
            </FormHelperText>
          </FormControl>

          <FormControl
            error={errors.phone ? true : false}
            variant="outlined"
            fullWidth
            size="small"
            margin="normal"
          >
            <MuiPhoneNumber
              onlyCountries={["us"]}
              disableCountryCode
              error={errors.phoneVerification ? true : false}
              id="phoneVerification"
              value={phoneVerification}
              defaultCountry={"us"}
              onChange={(phone) => phoneVerificationUpdate(phone)}
              color="primary"
              variant="outlined"
              label="Phone Verification"
              inputClass={classes.inputText}
              InputProps={{
                error: errors.phoneVerification ? true : false,
              }}
              inputProps={{
                placeholder: "xxx-xxx-xxxx",
                autocomplete: "phone",
                form: {
                  autocomplete: "off",
                },
              }}
              disableDropdown
              required
              size="small"
              InputLabelProps={{
                error: errors.phoneVerification ? true : false,
                classes: {
                  root: classes.inputLabel,
                  asterisk: classes.asterisk,
                },
              }}
            />

            <FormHelperText error variant="outlined">
              {errors.phoneVerification}
            </FormHelperText>
          </FormControl>

          <div className={classes.title1}>
            {" "}
            <Grid
              container
              justify="space-between"
              alignItems="center"
              xs={12}
              md={12}
            >
              <Grid key="1" item>
                CONTACT INFORMATION
              </Grid>
              <Grid
                key="2"
                item
                style={{ color: "#a2a2a2", cursor: "pointer" }}
                onClick={() => setContactModal(true)}
              >
                <QuestionAnswerIcon style={{ verticalAlign: "middle" }} /> HAVE
                A QUESTION?
              </Grid>
            </Grid>
          </div>

          <FormControl
            error={errors.address ? true : false}
            variant="outlined"
            fullWidth
            size="small"
            margin="normal"
          >
            <InputLabel
              htmlFor="address"
              classes={{
                root: classes.inputLabel,
                asterisk: classes.asterisk,
              }}
              required
            >
              Address
            </InputLabel>
            <OutlinedInput
              id="address"
              value={address}
              onChange={(e) => onChange(e)}
              label="Address"
              className={classes.inputText}
            />

            <FormHelperText error variant="outlined">
              {errors.address}
            </FormHelperText>
          </FormControl>

          <FormControl
            error={errors.city ? true : false}
            variant="outlined"
            fullWidth
            size="small"
            margin="normal"
          >
            <InputLabel
              htmlFor="city"
              classes={{
                root: classes.inputLabel,
                asterisk: classes.asterisk,
              }}
              required
            >
              City
            </InputLabel>
            <OutlinedInput
              id="city"
              value={city}
              onChange={(e) => onChange(e)}
              label="City"
              className={classes.inputText}
              inputProps={{ style: { textTransform: "capitalize" } }}
            />

            <FormHelperText error variant="outlined">
              {errors.city}
            </FormHelperText>
          </FormControl>

          <FormControl
            error={errors.zipcode ? true : false}
            variant="outlined"
            fullWidth
            size="small"
            margin="normal"
          >
            <InputLabel
              htmlFor="zipCode"
              classes={{
                root: classes.inputLabel,
                asterisk: classes.asterisk,
              }}
              required
            >
              Zip Code
            </InputLabel>
            <OutlinedInput
              id="zipCode"
              value={zipCode}
              onChange={(e) => onChange(e)}
              label="Zip Code"
              className={classes.inputText}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9*",
              }}
              // maxLength="5"
              onInput={(e) => {
                e.target.value = e.target.value.slice(0, 5);
              }}
            />

            <FormHelperText error variant="outlined">
              {errors.zipcode}
            </FormHelperText>
          </FormControl>
        </div>
      </form>
      {personalModal && (
        <FaqModal
          modal={personalModal}
          setModal={setPersonalModal}
          faqs={personalFaqs}
          title="Personal Information"
        />
      )}

      {contactModal && (
        <FaqModal
          modal={contactModal}
          setModal={setContactModal}
          faqs={contactFaqs}
          title="Contact Information"
        />
      )}
    </div>
  );
};

Booking.propTypes = {
  userDetails: PropTypes.object.isRequired,
  setUserDetails: PropTypes.func.isRequired,
  validateUser: PropTypes.func.isRequired,
  setValidateUser: PropTypes.func.isRequired,
  toggleNext: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userDetails: state.booking.userDetails,
  validateUser: state.booking.validateUser,
  nextStep: state.step.nextStep,
  next: state.step.next,
});

export default connect(mapStateToProps, {
  setUserDetails,
  nextStep,
  setValidateUser,
  toggleNext,
})(Booking);
