import React, { useEffect } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles(() => ({
  header1: {
    display: "flex",
    justifyContent: "flex-start",
    color: "#77bbff",
    fontSize: 24,
    position: "fixed",
    backgroundColor: "#f0eff4",
    zIndex: 1113,
    paddingTop: 45,
    paddingLeft: 10,
    marginTop: -55,
    width: "55%",
    "@media (max-width: 768px)": {
      fontSize: 16,
      animation: "none!important",
      width: "90%",
      backgroundColor: "#f0eff4",
    },
  },
  stepNo: {
    fontWeight: 700,
  },
  followIcon: {
    fontSize: "20px !important",
    marginTop: 6,
    "@media (max-width: 768px)": {
      fontSize: "16px !important",
      marginTop: 3,
    },
  },
  stepTitle: {
    color: "#48465c",
    fontWeight: 430,
    fontFamily: "Montserrat, sans-serif",
    lineHeight: 1.5,
    letterSpacing: ".3px",
    "@media (max-width: 768px)": {
      color: "#7c7c7c",
    },
  },
  textFilter: {
    margin: 0,
  },
}));

const Title = ({ name, name2, fade, step }) => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fade in={true} mountOnEnter unmountOnExit timeout={1000}>
      <div className={classes.header1}>
        <span className={classes.stepNo}>{step}</span>
        <ArrowForwardIcon />
        &nbsp;&nbsp;
        <span className={classes.stepTitle}>
          <p className={classes.textFilter}>{name}</p>

          <Fade in={fade} mountOnEnter unmountOnExit timeout={1000}>
            <p className={classes.textFilter}>{name2}</p>
          </Fade>
        </span>
      </div>
    </Fade>
  );
};

export default Title;
