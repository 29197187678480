import React from "react";
import TvSize from "./TvSize";
import Bracket from "./Bracket";
import OrderDetails from "./OrderDetails";

import Options from "./Options";
import AddOns from "./AddOns";
import ContactDetails from "./ContactDetails";
import BookingDateTime from "./BookingDateTime";
import Confirmation from "./Confirmation";
import Thanks from "./Thanks";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const BookingForm = ({ currentStep }) => {
  switch (currentStep) {
    case 1:
    default:
      return <TvSize />;

    case 2:
      return (
        <>
          <Bracket />
          <OrderDetails />
        </>
      );

    case 3:
      return (
        <>
          <Options />
          <OrderDetails />
        </>
      );

    case 4:
      return (
        <>
          <AddOns />
          <OrderDetails />
        </>
      );

    case 5:
      return (
        <>
          <ContactDetails />
        </>
      );

    case 6:
      return (
        <>
          <BookingDateTime />
          <OrderDetails />
        </>
      );

    case 7:
      return (
        <>
          <Confirmation />
        </>
      );

    case 8:
      return (
        <>
          <Thanks />
        </>
      );
  }
};

BookingForm.propTypes = {
  currentStep: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currentStep: state.step.current,
});

export default connect(mapStateToProps, {})(BookingForm);
