import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { nextStep, prevStep, setDrawer } from "../../actions/step";
import { nextBracket, prevBracket } from "../../actions/step";
import {
  toggleModal,
  setValidateUser,
  createBookingRequest,
} from "../../actions/booking";
import OrderDetailsMobile from "../OrderDetailsMobile";

// Material UI Components
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles(() => ({
  body: {
    padding: 10,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f0eff4",
    "@media (max-width: 768px)": {
      backgroundColor: "#f0eff4",
    },
  },
  progress: {
    backgroundColor: "#dae9f7",
    display: "block",
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#77bbff !important",
    },
  },
  explain: {
    width: "100%",
    color: "#7c7c7c",
    fontWeight: 500,
    fontSize: 13,
    display: "flex",
  },
  total: {
    "& span": {
      fontSize: 19,
      fontWeight: 1000,
      display: "none",
      "@media (max-width: 1024px)": {
        fontSize: 18,
        fontWeight: "bold",
        display: "block",
        textAlign: "center",
      },
    },
    width: "100%",
    "@media (max-width: 768px)": {
      fontSize: 13,
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 400,
      color: "#46515b",
    },
  },
  details: {
    color: "#3C99FF",
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 500,
    display: "none",
    "@media (max-width: 1024px)": {
      display: "block",
    },
  },
  moveIcons: {
    display: "flex",
    justifyContent: "flex-end",

    alignItems: "center",
    "& button": {
      fontWeight: 500,
      fontSize: 18,
      textTransform: "none",
      "@media (max-width:822px)": {
        fontSize: 14,
      },
      "@media (max-width:768px)": {
        fontSize: 12,
        padding: "7px 10px",
        "& svg": {
          fontSize: "18px !important",
        },
      },
      "@media (max-width: 768px)": {
        fontSize: 12,
        padding: "7px 10px",
        "& svg": {
          fontSize: "18px !important",
        },
      },
      "& span": {
        margin: 0,
      },
      "&:hover": {
        color: "#03030380",
      },
    },
  },
  drawer: {
    borderRadius: 20,
    display: "none",
    "& .MuiDrawer-paper": {
      height: "90vh!important",
    },
    "@media (max-width: 1024px)": {
      display: "block",
    },
  },
  back: {
    "& button": {
      //  backgroundColor: "#77bbff!important",
      color: "#fff!important",
      border: 0,
      boxShadow: "none!important",
      borderRadius: 20,
    },
    marginRight: 50,
    "@media (max-width: 768px)": {
      marginRight: 10,
    },
  },
  forward: {
    "& button": {
      backgroundColor: "#77bbff!important",
      color: "#fff!important",
      borderRadius: 20,
      border: 0,
      boxShadow: "none!important",
      "@media (max-width: 768px)": {},
    },
  },

  footer: {
    position: "fixed",
    width: "59%",
    bottom: 0,
    zIndex: 1,
    "@media (max-width: 768px)": {
      width: "100% !important",
    },
  },
}));

const Footer = ({
  orderTotal,
  step: { current, next },
  nextStep,
  prevStep,
  currentBracket,
  nextBracket,
  prevBracket,
  tvSizes,
  discount,
  toggleModal,
  timeCount,
  setValidateUser,
  drawer,
  setDrawer,
  bookingCharges,
  createBookingRequest,
  bookingDetails,
  orderDetails,
}) => {
  const classes = useStyles();

  const tvSizesTotal = tvSizes.filter((tvSize) => tvSize.qty > 0);

  const submitRequest = async (e) => {
    e.preventDefault();
    createBookingRequest(bookingDetails, orderDetails);
    nextStep();
  };

  return (
    <div className={classes.footer}>
      <div>
        {/* progress bar */}
        <LinearProgress
          variant="determinate"
          value={((current - 1) * 100) / 7}
          className={classes.progress}
        />
        {current !== 8 && (
          <div className={classes.body}>
            <div
              className={classes.explain}
              style={{
                //
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {" "}
              <div
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {current !== 7 && (
                  <>
                    <div className={classes.total}>
                      <span> ${orderTotal + bookingCharges - discount}</span>
                    </div>
                    <span
                      onClick={() => setDrawer(true)}
                      className={classes.details}
                    >
                      Quote Information
                    </span>
                  </>
                )}
              </div>
              <Drawer
                anchor={"bottom"}
                open={drawer}
                onClose={() => setDrawer(false)}
                className={classes.drawer}
                transitionDuration={600}
                style={{
                  borderRadius: 20,
                }}
              >
                <OrderDetailsMobile />
              </Drawer>
              <div className={classes.moveIcons}>
                {current !== 1 ? (
                  <div className={classes.back}>
                    <Button
                      variant="contained"
                      startIcon={<ArrowBackIosIcon />}
                      onClick={(e) =>
                        current === 2 && currentBracket !== 1
                          ? prevBracket()
                          : prevStep()
                      }
                    >
                      Back
                    </Button>
                  </div>
                ) : (
                  ""
                )}

                <div className={classes.forward}>
                  <Button
                    disabled={!next}
                    style={{ opacity: next ? 1 : 0.5 }}
                    variant="contained"
                    onClick={(e) =>
                      current === 7
                        ? submitRequest(e)
                        : current === 5
                        ? setValidateUser(true)
                        : current === 6 && timeCount < 3
                        ? toggleModal(true)
                        : current === 2 &&
                          currentBracket !== tvSizesTotal.length
                        ? nextBracket()
                        : nextStep()
                    }
                    endIcon={<ArrowForwardIosIcon />}
                  >
                    {current !== 7 ? "Next Step" : "Submit"}
                  </Button>
                </div>
              </div>
              {/* mobile */}
              <Drawer
                anchor={"bottom"}
                className={classes.drawer}
                transitionDuration={600}
                style={{
                  borderRadius: 20,
                }}
              ></Drawer>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  discount: state.order.discount,
  orderTotal: state.order.total,
  tvSizes: state.order.tvs,
  step: state.step,
  currentBracket: state.step.currentBracket,
  timeCount: state.booking.timeCount,
  drawer: state.step.drawer,
  bookingCharges: state.booking.bookingCharges,
  bookingDetails: state.booking,
  orderDetails: state.order,
});

Footer.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  nextBracket: PropTypes.func.isRequired,
  prevBracket: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  setValidateUser: PropTypes.func.isRequired,
  setDrawer: PropTypes.func.isRequired,
  createBookingRequest: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  prevBracket,
  nextBracket,
  nextStep,
  prevStep,
  toggleModal,
  setValidateUser,
  setDrawer,
  createBookingRequest,
})(Footer);
