import {
  TOGGLE_TV,
  ADD_TV,
  REMOVE_TV,
  UPDATE_LIFT,
  TOGGLE_BRACKET,
  ADD_BRACKET,
  REMOVE_BRACKET,
  TOGGLE_OPTION,
  INCREASE_OPTION,
  DECREASE_OPTION,
  TOGGLE_ADDON,
  INCREASE_ADDON,
  DECREASE_ADDON,
  APPLY_DISCOUNT,
} from "./types";

export const toggleTv = (id, status) => (dispatch) => {
  dispatch({
    type: TOGGLE_TV,
    payload: {
      id,
      status,
    },
  });

  dispatch({
    type: APPLY_DISCOUNT,
  });
};

export const addTv = (id, size, price) => (dispatch) => {
  dispatch({
    type: ADD_TV,
    payload: {
      id,
      size,
      price,
    },
  });

  dispatch({
    type: APPLY_DISCOUNT,
  });
};

export const removeTv = (id, price) => (dispatch) => {
  dispatch({
    type: REMOVE_TV,
    payload: {
      id,
      price,
    },
  });

  dispatch({
    type: APPLY_DISCOUNT,
  });
};

export const updateLift = (id, lift) => (dispatch) => {
  dispatch({
    type: UPDATE_LIFT,
    payload: {
      id,
      lift,
    },
  });
};

export const toggleBracket = (tvid, bracket, status) => (dispatch) => {
  dispatch({
    type: TOGGLE_BRACKET,
    payload: { tvid, bracket, status },
  });
};

export const addBracket = (tvid, bracket) => (dispatch) => {
  dispatch({
    type: ADD_BRACKET,
    payload: { tvid, bracket },
  });
};

export const removeBracket = (tvid, bracket) => (dispatch) => {
  dispatch({
    type: REMOVE_BRACKET,
    payload: { tvid: tvid, bracket: bracket },
  });
};

export const toggleOption = (id, status) => (dispatch) => {
  dispatch({
    type: TOGGLE_OPTION,
    payload: { id, status },
  });

  dispatch({
    type: APPLY_DISCOUNT,
  });
};

export const increaseOption = (id) => (dispatch) => {
  dispatch({
    type: INCREASE_OPTION,
    payload: id,
  });

  dispatch({
    type: APPLY_DISCOUNT,
  });
};

export const decreaseOption = (id) => (dispatch) => {
  dispatch({
    type: DECREASE_OPTION,
    payload: id,
  });

  dispatch({
    type: APPLY_DISCOUNT,
  });
};

export const toggleAddon = (id, status) => (dispatch) => {
  dispatch({
    type: TOGGLE_ADDON,
    payload: { id, status },
  });

  dispatch({
    type: APPLY_DISCOUNT,
  });
};

export const increaseAddOn = (id) => (dispatch) => {
  dispatch({
    type: INCREASE_ADDON,
    payload: id,
  });

  dispatch({
    type: APPLY_DISCOUNT,
  });
};

export const decreaseAddOn = (id) => (dispatch) => {
  dispatch({
    type: DECREASE_ADDON,
    payload: id,
  });

  dispatch({
    type: APPLY_DISCOUNT,
  });
};
