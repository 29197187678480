import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Title from "./layout/Title";
import MonthCarousel from "./layout/MonthCarousel";
import FaqModal from "./layout/FaqModal";

// OWl  Carousel  CSS
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";

// Material UI
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

import {
  addTime,
  removeTime,
  toggleMoreDays,
  toggleModal,
} from "../actions/booking";
import { nextStep } from "../actions/step";
import { toggleNext } from "../actions/step";
import Dates from "./layout/Dates";
import numWords from "num-words";

const BookingDateTime = ({
  timeSlots,
  addTime,
  removeTime,
  next,
  toggleNext,
  timeCount,
  toggleMoreDays,
  moreDays,
  selectedDates,
  modal,
  toggleModal,
  nextStep,
}) => {
  const useStyles = makeStyles(() => ({
    root: {
      borderRadius: 20,
      padding: "15px 0px",
      backgroundColor: "#fff",
      width: "100%",
      boxShadow: "0 2px 6px 0 rgba(0, 0, 0, .1)",
      border: "1px solid rgba(218,220,228,.1)",
      "@media (max-width: 768px)": {
        borderRadius: 10,
        padding: "8px 0px",
        width: "auto",
        boxShadow: "none",
        border: "none",
      },
    },

    moreDaysDive: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      margin: "10px 0px 20px 0px",
    },
    moreDaysButton: {
      width: "25%",
      background: "#f2f2f2",
      color: "#030303",
      fontSize: 9,
      fontWeight: 500,
      fontFamily: "Montserrat, sans-serif",
      padding: "10px 20px !important",
      "@media (max-width: 768px)": {
        width: "30%",
        padding: "3px 0px !important",
      },
    },
    line: {
      marginLeft: 20,
      marginRight: 20,
      opacity: 0.1,
    },
    dayBooking: {
      display: "flex",
      justifyContent: "center",
      color: "#030303",
      flexFlow: "column",
      textAlign: "center",
      "& span": {
        padding: "20px 0px",
        fontSize: 14,
        fontWeight: 500,
        fontFamily: "Montserrat, sans-serif",
        opacity: 0.6,
        "@media (max-width: 768px)": {
          fontSize: 12,
        },
      },
    },
    spaceDiv: {
      padding: 10,
    },

    timesDiv: {
      display: "block",
    },

    title1: {
      fontWeight: 500,
      color: "#a2a2a2",
      fontSize: 12,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px 10px",
      marginTop: 10,
      left: 0,
      opacity: 1,
      position: "relative",
      "@media (max-width: 768px)": {
        animation: "none!important",
        opacity: 1,
        fontSize: 10,
      },
    },

    bookingForm: {
      paddingTop: 36,
      minHeight: 750,
      "@media (max-width: 768px)": {
        paddingTop: 24,
        minHeight: 551,
      },
    },

    timeDiv: {
      width: "46%",
      float: "left",
      display: "flex",
      justifyContent: "center",
      marginTop: 10,
      fontSize: 6,
      fontFamily: "Montserrat, sans-serif",
      margin: "5px 2%",
    },
    bookingTime: {
      width: "90%",
      border: "2px solid #ebebeb",
      textAlign: "center",
      padding: 8,
      fontSize: 13,
      fontFamily: "Montserrat, sans-serif",
      borderRadius: 5,
      fontWeight: 500,
      cursor: "pointer",
      "&:hover": {
        borderColor: "#77bbff",
        color: "#77bbff",
      },
      "@media (max-width: 768px)": {
        fontSize: 12,
      },
    },

    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& div:focus": {
        outline: "none",
      },
    },

    paper: {
      backgroundColor: "white",
      border: 0,
      boxShadow: 5,
      paddingTop: "20px",
      width: "40%",
      display: "flex",
      alignItems: "center",
      flexFlow: "column",
      justifyContent: "space-between",
      minHeight: 150,
      borderRadius: 20,
      "@media (max-width: 768px)": {
        width: "90%",
      },
    },
    note: {
      color: "#000000",
      alignItems: "center",
      fontSize: 18,
      fontWeight: 500,
      opacity: 0.9,
      textAlign: "center",
      padding: "20px",
      lineHeight: 1,
      letterSpacing: "-0.5",
      "& p": {
        cursor: "pointer",
        margin: "0 0",
      },
    },
    btnDiv: {
      marginTop: 30,
      marginBottom: 30,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& button:nth-child(1)": {
        fontSize: 13,
        background: "#77bbff",
        color: "white",
        padding: "6px 60px",
      },
      "& button:nth-child(2)": {
        fontSize: 13,
        textDecoration: "underline",
        textDecorationColor: "#77bbff",
        marginLeft: 10,
      },
    },
    close: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      marginRight: 22,
      "& button": {
        padding: 0,
        "& svg": {
          fontSize: "2rem",
        },
      },
    },

    makeSure: {
      // background: "#f0eff4",
      color: "#000000",
    },
  }));

  const classes = useStyles();

  const handleTime = (timeSlot) => {
    selectedDates[selectedDates.length - 1].times.includes(timeSlot)
      ? removeTime(selectedDates[selectedDates.length - 1].date, timeSlot)
      : addTime(selectedDates[selectedDates.length - 1].date, timeSlot);
  };

  useEffect(() => {
    if (timeCount > 0 && !next) {
      toggleNext(true);
    } else if (timeCount < 1 && next) {
      toggleNext(false);
    }
  }, [timeCount, next, toggleNext]);

  const handleClose = () => {
    toggleModal(false);
  };

  const [faqModal, setFaqModal] = useState(false);

  const faqs = [
    {
      question: "How do I choose more days? I see only three dates",
      answer:
        "Please click on the button ‘more days’ to choose the desired date",
    },
  ];

  return (
    <div>
      <Title name={"Booking"} step="6" />

      <div className={classes.bookingForm}>
        <div className={classes.title1}>
          {" "}
          <Grid
            container
            justify="space-between"
            alignItems="center"
            xs={12}
            md={12}
          >
            <Grid key="1" item>
              CHOOSE YOUR DATE AND TIME
            </Grid>
            <Grid
              key="2"
              item
              style={{ color: "#a2a2a2", cursor: "pointer" }}
              onClick={() => setFaqModal(true)}
              xs={5}
              md={3}
            >
              <QuestionAnswerIcon style={{ verticalAlign: "middle" }} /> HAVE A
              QUESTION?
            </Grid>
          </Grid>
        </div>
        <Paper elevation={0} className={classes.root}>
          {/* If More Days is selected, Display Month Carousel else Week Carousel */}
          {!moreDays ? (
            <Dates
              key={
                selectedDates.length > 0 &&
                selectedDates[selectedDates.length - 1].times.length
              }
            />
          ) : (
            <MonthCarousel />
          )}

          <div className={classes.moreDaysDive}>
            <Button
              className={classes.moreDaysButton}
              onClick={(e) => toggleMoreDays()}
            >
              {/* change to 1 week or month */}
              {moreDays ? "Back" : "More Days"}
            </Button>
          </div>

          {selectedDates.length > 0 && (
            <div>
              <hr className={classes.line} />
              <div className={classes.dayBooking}>
                <span>What arrival time suits you best?</span>
                <div className={classes.timesDiv}>
                  {timeSlots.map((timeSlot) => (
                    <div className={classes.timeDiv} key={timeSlot}>
                      <div
                        className={classes.bookingTime}
                        onClick={(e) => handleTime(timeSlot.id)}
                        // if booking time is selected, blue background is added
                        style={
                          selectedDates[
                            selectedDates.length - 1
                          ].times.includes(timeSlot.id)
                            ? {
                                borderColor: "#77bbff",
                                color: "#77bbffcc",
                              }
                            : {
                                borderColor: "#ebebeb",
                                color: "#030303cc",
                              }
                        }
                      >
                        {timeSlot.time}
                      </div>
                    </div>
                  ))}
                </div>
                <div className={classes.spaceDiv}></div>
                {selectedDates[selectedDates.length - 1].times.filter(
                  (time) => time === 5 || time === 6
                ).length > 0 && (
                  <span
                    className={classes.makeSure}
                    onLoad={window.scrollTo(0, document.body.scrollHeight)}
                  >
                    Please make sure work is allowed at that time
                  </span>
                )}
              </div>
            </div>
          )}
        </Paper>
      </div>

      {/* Display Modal if less than 2 times are selected */}

      {timeCount < 3 && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={modal}>
            <div className={classes.paper}>
              <div className={classes.close}>
                <IconButton
                  aria-label="close"
                  onClick={(e) => {
                    handleClose();
                    nextStep();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>

              <div className={classes.note}>
                <p style={{ lineHeight: "25px", padding: "10px" }}>
                  For faster confirmation, please choose{" "}
                  {numWords(3 - timeCount)} more appointment{" "}
                  {3 - timeCount === 1 ? "time" : "times"}.
                </p>
              </div>

              <Grid container style={{ marginBottom: 0 }}>
                <Grid
                  item
                  xs={6}
                  style={{
                    borderLeft: 0,
                    borderBottom: 0,
                    textAlign: "center",

                    borderColor: "#f5f5f5",
                    borderStyle: "solid",
                  }}
                >
                  <Button
                    onClick={(e) => {
                      handleClose();
                      nextStep();
                    }}
                    style={{
                      height: "100%",
                      width: "100%",
                      padding: 15,
                    }}
                  >
                    Continue Anyways
                  </Button>
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{
                    borderRight: 0,
                    borderLeft: 0,
                    borderBottom: 0,
                    textAlign: "center",

                    borderColor: "#f5f5f5",
                    borderStyle: "solid",
                  }}
                >
                  <Button
                    onClick={handleClose}
                    style={{
                      fontWeight: 600,
                      width: "100%",
                      color: "#77bbff",
                      height: "100%",
                      padding: "15px",
                    }}
                  >
                    Add more times
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
      )}

      {faqModal && (
        <FaqModal
          modal={faqModal}
          setModal={setFaqModal}
          faqs={faqs}
          title="Booking Questions"
        />
      )}
    </div>
  );
};

BookingDateTime.propTypes = {
  selectedDates: PropTypes.array.isRequired,
  addTime: PropTypes.func.isRequired,
  removeTime: PropTypes.func.isRequired,
  toggleNext: PropTypes.func.isRequired,
  toggleMoreDays: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  selectedDates: state.booking.selectedDates,
  timeSlots: state.booking.timeSlots,
  next: state.step.next,
  timeCount: state.booking.timeCount,
  moreDays: state.booking.moreDays,
  modal: state.booking.modal,
});

export default connect(mapStateToProps, {
  addTime,
  removeTime,
  toggleNext,
  toggleMoreDays,
  toggleModal,
  nextStep,
})(BookingDateTime);
