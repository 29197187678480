import React from "react";
import BookingForm from "./components/BookingForm";
import Footer from "./components/layout/Footer";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { makeStyles } from "@material-ui/core/styles";

import "./App.css";

import theme from "./theme";
import { ThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

function App() {
  const useStyles = makeStyles(() => ({
    steps: {
      width: "70%",
      height: "100%",
      margin: "0 auto",
      display: "flex",
      justifyContent: "center",
      position: "relative",
      "@media (max-width: 768px)": {
        width: "100% !important",
        left: "0%!important",
      },
    },
    step: {
      width: "80%",
      paddingTop: 45,
      "@media (max-width: 768px)": {
        width: "95%",
        marginBottom: 40,
      },
      "& svg": {
        fontSize: 30,
        "@media (max-width: 768px)": {
          fontSize: 19,
        },
      },
      "& button": {
        padding: 0,
      },
    },
  }));
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Typography>
        <Provider store={store}>
          <div className={classes.steps}>
            <div className={classes.step} id="step">
              <BookingForm />
            </div>
            <Footer />
          </div>
        </Provider>
      </Typography>
    </ThemeProvider>
  );
}

export default App;
