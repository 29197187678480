import React, { useState } from "react";
import Title from "./layout/Title";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { editStep } from "../actions/step";
import { setSpecialInstructions } from "../actions/booking";
import moment from "moment";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const Confirmation = ({
  editStep,
  tvSizes,
  brackets,
  options,
  addOns,
  selectedDates,
  timeSlots,
  userDetails,
  discount,
  orderTotal,
  bookingCharges,
  setSpecialInstructions,
  specialInstructions,
}) => {
  const useStyles = makeStyles(() => ({
    root1: {
      margin: 0,
      marginBottom: -150,
      paddingBottom: 150,
      opacity: 1,
      position: "relative",
      left: 0,
      fontWeight: 500,
      fontFamily: "Montserrat, sans-serif",
      color: "#000",
      fontSize: 14,

      "@media (max-width: 768px)": {
        animation: "none!important",
        marginBottom: -105,
        paddingBottom: 105,
      },
    },
    paper: {
      marginTop: 20,
      flexGrow: 1,
      borderRadius: 20,
      padding: 17,
      backgroundColor: "#fff",
      opacity: 0.9,
      boxShadow: "0 2px 6px 0 rgba(0, 0, 0, .1)",
      border: "1px solid rgba(218,220,228,.1)",
      "&:hover": {
        opacity: 1,
      },
      "@media (max-width: 768px)": {
        marginTop: 20,
        padding: "10px 8px",
        borderRadius: 10,
        opacity: 1,
        boxShadow: "none",
        border: "none",
      },
    },
    line: {
      margin: "20px -20px",
      opacity: 0.1,
      "@media (max-width: 768px)": {
        margin: "10px -8px",
      },
    },

    body: {
      paddingTop: 50,
      minHeight: 731,
      "@media (max-width: 768px)": {
        paddingTop: 30,
        minHeight: 544,
      },
    },

    helpIcon: {
      fontSize: "20px !important",
      border: "1px solid #e7e7e7",
      borderRadius: "0px",
      width: "26px",
      color: "#77bbff",
      "@media (max-width: 768px)": {
        fontSize: "12px !important",
        border: "1px solid #e7e7e7",
        borderRadius: "12px",
        width: "16px",
      },
    },

    orderTotal: {
      fontSize: 16,
      fontWeight: "bold",
    },

    inputLabel: {
      color: "black",
      fontSize: 14,
    },

    inputText: {
      borderRadius: 8,
      boxShadow: "0 2px 6px 0 rgba(0, 0, 0, .1)",
      backgroundColor: "#fff",
    },
  }));
  const classes = useStyles();

  setTimeout(() => setTime(true), 500);
  const [timeout, setTime] = useState(false);

  const selectedSizes = tvSizes.filter((tv) => tv.qty !== 0);

  const [charsLeft, setCharsLeft] = useState(140 - specialInstructions.length);

  const onChange = (e) => {
    setSpecialInstructions(e.target.value);
    setCharsLeft(140 - e.target.value.length);
  };

  return (
    <div>
      <Title name={"Confirmation"} step="7" />
      <div className={classes.body}>
        <div className={classes.root1}>
          {/* Step 5: User Details */}

          <Grow
            in={timeout}
            style={{ transformOrigin: "0 0 0" }}
            {...{ timeout: 500 }}
          >
            <div>
              <Paper elevation={0} className={classes.paper}>
                <div>
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    xs={12}
                  >
                    <Grid key="1" item xs={11} md={10}>
                      {
                        <>
                          {userDetails.name} <br /> {userDetails.address} <br />{" "}
                          {userDetails.city} - {userDetails.zipCode} <br />
                          {userDetails.phone}
                        </>
                      }
                    </Grid>

                    <Grid key="3" item xs={1} md={1}>
                      <IconButton
                        aria-label="help"
                        onClick={() => editStep(5)}
                        className={classes.helpIcon}
                      >
                        ✓
                      </IconButton>
                      <br /> Edit
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </div>
          </Grow>

          {/* Step 6: Date and Time Slot */}

          <Grow
            in={timeout}
            style={{ transformOrigin: "0 0 0" }}
            {...{ timeout: 1000 }}
          >
            <div>
              <Paper elevation={0} className={classes.paper}>
                <div>
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    xs={12}
                  >
                    <Grid key="1" item xs={11} md={10}>
                      {selectedDates.map((date) => (
                        <div>
                          {moment(date.date, "DDMMYYYY").format("dddd, Do MMM")}
                          :{" "}
                          {date.times.map(
                            (time, index) =>
                              timeSlots.find((timeSlot) => timeSlot.id === time)
                                .time +
                              (index !== date.times.length - 1 ? ", " : "")
                          )}
                        </div>
                      ))}
                    </Grid>

                    <Grid key="3" item xs={1} md={1}>
                      <IconButton
                        aria-label="help"
                        onClick={() => editStep(6)}
                        className={classes.helpIcon}
                      >
                        ✓
                      </IconButton>
                      <br /> Edit
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </div>
          </Grow>

          {/* Step 1 & 2: TV Sizes and Brackets */}

          {selectedSizes.map((tv) => (
            <Grow
              in={timeout}
              style={{ transformOrigin: "0 0 0" }}
              {...{ timeout: 1500 }}
            >
              <div>
                <Paper elevation={0} className={classes.paper}>
                  <div>
                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                      xs={12}
                    >
                      <Grid key="1" item xs={11} md={10}>
                        <div>
                          {tv.size} TV x {tv.qty} <br />
                          {
                            brackets.find((bracket) => bracket.tvid === tv.id)
                              .type
                          }{" "}
                          Bracket x{" "}
                          {
                            brackets.find((bracket) => bracket.tvid === tv.id)
                              .qty
                          }
                        </div>
                      </Grid>

                      <Grid key="2" item xs={1} md={1}>
                        <IconButton
                          aria-label="help"
                          onClick={() => editStep(1)}
                          className={classes.helpIcon}
                        >
                          ✓
                        </IconButton>
                        <br /> Edit
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </div>
            </Grow>
          ))}

          {/* Step 3: Options */}
          {options.filter((option) => option.selected === true).length > 0 && (
            <Grow
              in={timeout}
              style={{ transformOrigin: "0 0 0" }}
              {...{ timeout: 2000 }}
            >
              <div>
                <Paper elevation={0} className={classes.paper}>
                  <div>
                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                      xs={12}
                    >
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        xs={10}
                      >
                        {options.map(
                          (option) =>
                            option.selected && (
                              <>
                                <Grid key="1" item xs={8} md={8}>
                                  {option.name}
                                </Grid>
                                <Grid key="2" item xs={2} md={2}>
                                  - {option.selected ? "YES" : "NO"}
                                </Grid>
                              </>
                            )
                        )}
                      </Grid>

                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        xs={1}
                        md={1}
                      >
                        <Grid key="3" item xs={1} md={1}>
                          <IconButton
                            aria-label="help"
                            onClick={() => editStep(3)}
                            className={classes.helpIcon}
                          >
                            ✓
                          </IconButton>
                          <br /> Edit
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </div>
            </Grow>
          )}

          {/* Step 4: Add Ons */}
          {addOns.filter((addOn) => addOn.selected === true).length > 0 && (
            <Grow
              in={timeout}
              style={{ transformOrigin: "0 0 0" }}
              {...{ timeout: 2500 }}
            >
              <div>
                <Paper elevation={0} className={classes.paper}>
                  <div>
                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                      xs={12}
                    >
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        xs={10}
                      >
                        {addOns.map(
                          (addOn) =>
                            addOn.selected && (
                              <>
                                <Grid key="1" item xs={8} md={8}>
                                  {addOn.name}
                                </Grid>
                                <Grid key="2" item xs={2} md={2}>
                                  - YES
                                </Grid>
                              </>
                            )
                        )}
                      </Grid>

                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        xs={1}
                        md={1}
                      >
                        <Grid key="3" item xs={2} md={1}>
                          <IconButton
                            aria-label="help"
                            onClick={() => editStep(4)}
                            className={classes.helpIcon}
                          >
                            ✓
                          </IconButton>
                          <br /> Edit
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </div>
            </Grow>
          )}

          {/* Total */}

          <Grow
            in={timeout}
            style={{ transformOrigin: "0 0 0" }}
            {...{ timeout: 3000 }}
          >
            <div>
              <Paper elevation={0} className={classes.paper}>
                <div>
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    xs={12}
                  >
                    <Grid key="1" item xs={10.5} md={10}>
                      Total
                    </Grid>

                    <Grid key="3">
                      <span className={classes.orderTotal}>
                        ${orderTotal + bookingCharges - discount}{" "}
                        {discount > 0 && <>(${discount} off)</>}
                      </span>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </div>
          </Grow>

          {/* Special Instructions */}

          <Grow
            in={timeout}
            style={{ transformOrigin: "0 0 0" }}
            {...{ timeout: 3500 }}
          >
            <FormControl
              variant="outlined"
              fullWidth
              size="small"
              margin="normal"
            >
              <InputLabel
                htmlFor="specialInstructions"
                classes={{
                  root: classes.inputLabel,
                }}
              >
                Special Instructions
              </InputLabel>
              <OutlinedInput
                id="specialInstructions"
                onChange={(e) => onChange(e)}
                value={specialInstructions}
                label="Special Instructions"
                className={classes.inputText}
                multiline
                rows={3}
                inputProps={{ maxLength: 140 }}
              />
            </FormControl>
          </Grow>
          <div
            style={{
              color: "#afafaf",
              textAlign: "right",
              fontSize: 12,
              marginRight: 10,
            }}
          >
            {charsLeft} Characters remaining
          </div>
        </div>
      </div>
    </div>
  );
};

Confirmation.propTypes = {
  editStep: PropTypes.func.isRequired,
  setSpecialInstructions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  tvSizes: state.order.tvs,
  brackets: state.order.brackets,
  options: state.order.options,
  addOns: state.order.addOns,
  selectedDates: state.booking.selectedDates,
  timeSlots: state.booking.timeSlots,
  userDetails: state.booking.userDetails,
  discount: state.order.discount,
  orderTotal: state.order.total,
  bookingCharges: state.booking.bookingCharges,
  specialInstructions: state.booking.specialInstructions,
});

export default connect(mapStateToProps, { editStep, setSpecialInstructions })(
  Confirmation
);
