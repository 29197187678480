import {
  ADD_DATE,
  REMOVE_DATE,
  SET_CAROUSEL_INDEX,
  SET_CURRENT_ITEM,
  ADD_TIME,
  REMOVE_TIME,
  TOGGLE_MORE_DAYS,
  SET_DATE_INDEX,
  TOGGLE_MODAL,
  SET_USER_DETAILS,
  SET_VALIDATE_USER,
  SET_SPECIAL_INSTRUCTIONS,
  CREATE_BOOKING_REQUEST,
} from "./types";
import axios from "axios";

export const addDate = (date, price) => (dispatch) => {
  dispatch({
    type: ADD_DATE,
    payload: { date, price },
  });
};

export const removeDate = (date) => (dispatch) => {
  dispatch({
    type: REMOVE_DATE,
    payload: { date },
  });
};

export const setCarouselIndex = (index) => (dispatch) => {
  dispatch({
    type: SET_CAROUSEL_INDEX,
    payload: index,
  });
};

export const setCurrentItem = (item) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_ITEM,
    payload: item,
  });
};

export const addTime = (date, time) => (dispatch) => {
  dispatch({
    type: ADD_TIME,
    payload: { date, time },
  });
};

export const removeTime = (date, time) => (dispatch) => {
  dispatch({
    type: REMOVE_TIME,
    payload: { date, time },
  });
};

export const toggleMoreDays = () => (dispatch) => {
  dispatch({
    type: TOGGLE_MORE_DAYS,
    payload: {},
  });
};

export const setDateIndex = (dateIndex) => (dispatch) => {
  dispatch({
    type: SET_DATE_INDEX,
    payload: dateIndex,
  });
};

export const toggleModal = (modal) => (dispatch) => {
  dispatch({
    type: TOGGLE_MODAL,
    payload: modal,
  });
};

export const setUserDetails = (userDetails) => (dispatch) => {
  dispatch({
    type: SET_USER_DETAILS,
    payload: userDetails,
  });
};

export const setValidateUser = (validateUser) => (dispatch) => {
  dispatch({
    type: SET_VALIDATE_USER,
    payload: validateUser,
  });
};

export const setSpecialInstructions = (specialInstructions) => (dispatch) => {
  dispatch({
    type: SET_SPECIAL_INSTRUCTIONS,
    payload: specialInstructions,
  });
};

export const createBookingRequest = (bookingDetails, orderDetails) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  const body = JSON.stringify({ bookingDetails, orderDetails });

  try {
    const res = await axios.post("/api/requests/create", body, config);

    dispatch({
      type: CREATE_BOOKING_REQUEST,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    console.log(errors);
  }
};
