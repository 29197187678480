import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Title from "./layout/Title";
import { toggleOption, increaseOption, decreaseOption } from "../actions/order";
import FaqModal from "./layout/FaqModal";

//Material UI
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { FormControl, Checkbox, Grid } from "@material-ui/core";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

// Section css
const useStyles = makeStyles(() => ({
  root1: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    padding: 17,
    backgroundColor: "#fff",
    margin: "30px 0px",
    fontFamily: "Montserrat, sans-serif",
    opacity: 1,
    position: "relative",
    left: 0,
    boxShadow: "0 2px 6px 0 rgba(0, 0, 0, .1)",
    border: "1px solid rgba(218,220,228,.1)",
    height: "50px",
    "&:hover": {
      opacity: 1,
    },
    "@media (max-width: 768px)": {
      borderRadius: 10,
      animation: "none!important",
      boxShadow: "none",
      border: "none",
    },
  },

  title: {
    width: "100%",
    fontWeight: 500,
    paddingBottom: 5,
    fontSize: 17,
    fontFamily: "Montserrat, sans-serif",
    color: "#000",
    "@media (max-width: 768px)": {
      fontSize: 14,
    },
  },
  explain: {
    width: "100%",
    fontSize: 13,
    fontWeight: 200,
    fontFamily: "Montserrat, sans-serif",
    "@media (max-width: 768px)": {
      fontSize: 11,
    },
  },
  radios: {
    width: "100%",
  },

  body: {
    paddingTop: 50,
    minHeight: 731,
    "@media (max-width: 768px)": {
      paddingTop: 50,
      minHeight: 544,
    },
  },

  sizeHeader1: {
    display: "flex",
    // padding: "45px 20px 2px 20px",
    marginBottom: -20,
    fontSize: 12,
    color: "#b7b7b7",
    fontWeight: 500,
    letterSpacing: "0.5px",
    lineHeight: 3,
    left: 0,
    opacity: 1,
    position: "relative",
    "@media (max-width: 768px)": {
      // padding: "25px 20px 2px 20px",
      fontSize: 10,
    },
  },

  switch: {
    color: "#77bbff !important",
  },

  qtyDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: 600,
    fontSize: 18,
    fontFamily: "Montserrat, sans-serif",
    "& button": {
      padding: 0,
    },
    "@media (max-width: 768px)": {
      fontSize: 16,
    },
  },

  addIcon: {
    color: "#77bbff",
  },
  removeIcon: {
    color: "#e7e7e7",
  },
}));
//  Main section
const Options = ({
  options,
  toggleOption,
  tvQty,
  increaseOption,
  decreaseOption,
}) => {
  const classes = useStyles();
  setTimeout(() => setTime(true), 500);
  const [timeout, setTime] = useState(false);

  const [modal, setModal] = useState(false);

  const faqs = [
    {
      question: "What is included into dismounting service?",
      answer: "Taking TV and wall bracket down, parching small holes.",
    },
    {
      question: "Do you transport TV from one location to another?",
      answer: "No, we do not.",
    },
  ];

  return (
    <>
      <div style={{ left: "-10%" }}>
        <Title
          name={"Choose the one that best fits your need (optional choice)"}
          step="3"
        />

        <div className={classes.body}>
          <div className={classes.sizeHeader1} id="sizeHeader">
            <Grid
              container
              justify="space-between"
              alignItems="center"
              xs={12}
              md={12}
            >
              <Grid key="1" item></Grid>
              <Grid
                key="2"
                item
                style={{ color: "#a2a2a2", cursor: "pointer" }}
                onClick={() => setModal(true)}
                xs={5}
                md={3}
              >
                <QuestionAnswerIcon style={{ verticalAlign: "middle" }} /> HAVE
                A QUESTION?
              </Grid>
            </Grid>
          </div>
          {options.map((option) => (
            <Grow
              in={timeout}
              style={{ transformOrigin: "0 0 0" }}
              {...{ timeout: 500 * option.id }}
            >
              <Paper
                container
                elevation={0}
                className={classes.root1}
                onClick={(e) => toggleOption(option.id, !option.selected)}
              >
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  xs={12}
                  md={12}
                >
                  <Grid key="1" item>
                    <div className={classes.radios}>
                      <FormControl
                        component="fieldset"
                        fullWidth="true"
                        size="small"
                      >
                        <Checkbox
                          checked={option.selected}
                          /*    onChange={(e) =>
                          toggleOption(option.id, e.target.checked)
                        } */
                          color="primary"
                          name="checkedB"
                          inputProps={{ "aria-label": "primary checkbox" }}
                          className={classes.switch}
                        />
                      </FormControl>
                    </div>
                  </Grid>

                  <Grid key="2" item xs={7} md={7}>
                    <div className={classes.title}>
                      {option.name} <br />
                      {option.price > 0 ? (
                        <> (+${option.price}) </>
                      ) : (
                        <>(free)</>
                      )}
                    </div>
                    <div className={classes.explain}>{option.sub}</div>
                  </Grid>

                  <Grid key="3" item xs={3} md={3}>
                    {option.selected && option.qty > 0 && (
                      <div
                        className={classes.qtyDiv}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <IconButton
                          aria-label="decrease"
                          onClick={(e) => {
                            decreaseOption(option.id);
                            //    e.stopPropagation();
                          }}
                        >
                          <RemoveCircleOutlineIcon
                            className={classes.removeIcon}
                          />
                        </IconButton>
                        <span>{option.qty}</span>
                        <IconButton
                          aria-label="increase"
                          onClick={(e) => {
                            (option.qty < tvQty || option.id === 2) &&
                              increaseOption(option.id);
                            //     e.stopPropagation();
                          }}
                        >
                          <AddCircleOutlineIcon className={classes.addIcon} />
                        </IconButton>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grow>
          ))}
        </div>
      </div>
      {modal && (
        <FaqModal
          modal={modal}
          setModal={setModal}
          faqs={faqs}
          title="TV Mounting Options"
        />
      )}
    </>
  );
};

Options.propTypes = {
  toggleOption: PropTypes.func.isRequired,
  increaseOption: PropTypes.func.isRequired,
  decreaseOption: PropTypes.func.isRequired,
};

// map props to state
const mapStateToProps = (state) => ({
  options: state.order.options,
  tvQty: state.order.tvQty,
});

export default connect(mapStateToProps, {
  toggleOption,
  increaseOption,
  decreaseOption,
})(Options);
