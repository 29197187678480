import {
  ADD_DATE,
  REMOVE_DATE,
  SET_CAROUSEL_INDEX,
  SET_CURRENT_ITEM,
  ADD_TIME,
  REMOVE_TIME,
  TOGGLE_MORE_DAYS,
  SET_DATE_INDEX,
  TOGGLE_MODAL,
  SET_USER_DETAILS,
  SET_VALIDATE_USER,
  SET_SPECIAL_INSTRUCTIONS,
  CREATE_BOOKING_REQUEST,
} from "../actions/types";

const initialState = {
  userDetails: {
    name: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    zipCode: "",
  },
  validateUser: false,
  timeSlots: [
    { id: 1, time: "9-10" },
    { id: 2, time: "10-12" },
    { id: 3, time: "1-3" },
    { id: 4, time: "3-5" },
    { id: 5, time: "5-7" },
    { id: 6, time: "7-8" },
  ],
  selectedDates: [],
  timeCount: 0,
  carouselIndex: 0,
  currentItem: 1,
  moreDays: false,
  dateIndex: 0,
  modal: false,
  bookingCharges: 0,
  specialInstructions: "",
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_DATE:
      return {
        ...state,
        selectedDates: [
          ...state.selectedDates,
          { date: payload.date, times: [], price: payload.price },
        ],
        bookingCharges:
          state.bookingCharges + (payload.price ? payload.price : 0),
      };

    case REMOVE_DATE:
      return {
        ...state,
        bookingCharges:
          state.bookingCharges -
          (state.selectedDates.find((date) => date.date === payload.date).price
            ? state.selectedDates.find((date) => date.date === payload.date)
                .price
            : 0),
        timeCount:
          state.timeCount -
          state.selectedDates.find((date) => date.date === payload.date).times
            .length,
        selectedDates: state.selectedDates.filter(
          (date) => date.date !== payload.date
        ),
      };

    case SET_CAROUSEL_INDEX: {
      return {
        ...state,
        carouselIndex: payload,
      };
    }

    case SET_CURRENT_ITEM: {
      return {
        ...state,
        currentItem: payload,
      };
    }

    case ADD_TIME:
      return {
        ...state,
        timeCount: state.timeCount + 1,
        selectedDates: state.selectedDates.map((selectedDate) => {
          if (selectedDate.date === payload.date) {
            return {
              ...selectedDate,
              times: [...selectedDate.times, payload.time],
            };
          } else {
            return selectedDate;
          }
        }),
      };

    case REMOVE_TIME:
      return {
        ...state,
        timeCount: state.timeCount - 1,
        selectedDates: state.selectedDates.map((selectedDate) => {
          if (selectedDate.date === payload.date) {
            return {
              ...selectedDate,
              times: selectedDate.times.filter((time) => time !== payload.time),
            };
          } else {
            return selectedDate;
          }
        }),
      };

    case TOGGLE_MORE_DAYS:
      return {
        ...state,
        moreDays: !state.moreDays,
      };

    case SET_DATE_INDEX:
      return {
        ...state,
        dateIndex: payload,
      };

    case TOGGLE_MODAL:
      return {
        ...state,
        modal: payload,
      };

    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: payload,
      };

    case SET_VALIDATE_USER:
      return {
        ...state,
        validateUser: payload,
      };

    case SET_SPECIAL_INSTRUCTIONS:
      return {
        ...state,
        specialInstructions: payload,
      };

    case CREATE_BOOKING_REQUEST:
      return {
        ...state,
        status: 1,
      };

    default:
      return state;
  }
}
