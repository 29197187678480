import {
  TOGGLE_TV,
  ADD_TV,
  REMOVE_TV,
  UPDATE_LIFT,
  TOGGLE_BRACKET,
  ADD_BRACKET,
  REMOVE_BRACKET,
  TOGGLE_OPTION,
  INCREASE_OPTION,
  DECREASE_OPTION,
  TOGGLE_ADDON,
  INCREASE_ADDON,
  DECREASE_ADDON,
  APPLY_DISCOUNT,
} from "../actions/types";

const initialState = {
  tvs: [
    {
      id: 1,
      size: "31” or smaller",
      price: 69,
      qty: 0,
      subtotal: 0,
    },
    { id: 2, size: "31-58''", price: 89, qty: 0, subtotal: 0 },
    {
      id: 3,
      size: "59” or larger",
      price: 109,
      qty: 0,
      subtotal: 0,
      lift: "ask",
      noLiftPrice: 139,
      liftPrice: 109,
    },
  ],
  tvQty: 0,
  brackets: [],
  bracketQty: 0,
  options: [
    {
      id: 1,
      name: "Above Fireplace",
      sub: "(no extra cost when on drywall/plaster wall)",
      price: 0,
      selected: false,
      display: "Above Fireplace",
      qty: 0,
    },
    {
      id: 2,
      name: "Dismount existing tv",
      sub: "(free, if we don’t remove the wall bracket)",
      price: 59,
      selected: false,
      display: "Dismounting",
      qty: 0,
    },
    {
      id: 3,
      name: "Brick, Stucco or Concrete Wall ",
      sub: "(we do not install on tile, stone)",
      price: 59,
      selected: false,
      display: "Brick Wall",
      qty: 0,
    },
    /*  {
      id: 4,
      name: "Remounting Service",
      sub: "(moving from one location to another the same day)",
      price: 69,
      selected: false,
      display: "Remounting",
      qty: 0,
    }, */
  ],
  optionsSubtotal: 0,
  addOns: [
    {
      id: 1,
      categoryID: 1,
      name: "Externally",
      price: 39,
      selected: false,
      qty: 0,
    },
    {
      id: 2,
      categoryID: 1,
      name: "Behind the Wall",
      price: 129,
      selected: false,
      qty: 0,
    },
    {
      id: 3,
      categoryID: 2,
      name: "Soundbar speaker installation",
      price: 49,
      selected: false,
      qty: 0,
    },
    {
      id: 4,
      categoryID: 2,
      name: "Small wall shelf installation",
      price: 49,
      selected: false,
      qty: 0,
    },
    {
      id: 5,
      categoryID: 2,
      name: "LED strip lights installation",
      price: 49,
      selected: false,
      qty: 0,
    },
    {
      id: 6,
      categoryID: 3,
      name: "Connect device without set up",
      price: 0,
      selected: false,
      qty: 0,
    },

    {
      id: 7,
      categoryID: 3,
      name: "HDMI",
      price: 20,
      selected: false,
      qty: 0,
    },
    {
      id: 8,
      categoryID: 3,
      name: "HDMI 2.1+",
      price: 35,
      selected: false,
      qty: 0,
    },
    {
      id: 9,
      categoryID: 3,
      name: "Optical",
      price: 20,
      selected: false,
      qty: 0,
    },
    {
      id: 10,
      categoryID: 3,
      name: "Basic Power Cable",
      price: 20,
      selected: false,
      qty: 0,
    },
    {
      id: 11,
      categoryID: 3,
      name: "Ethernet",
      price: 20,
      selected: false,
      qty: 0,
    },
    {
      id: 12,
      categoryID: 3,
      name: "Coax Cable",
      price: 20,
      selected: false,
      qty: 0,
    },
  ],
  addOnsSubtotal: 0,
  discount: 0,
  total: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_TV:
      const selectedTv = state.tvs.find((tv) => tv.id === payload.id);

      return {
        ...state,
        total:
          state.total +
          (payload.status
            ? selectedTv.price
            : -(selectedTv.price * selectedTv.qty)),
        tvQty: state.tvQty + (payload.status ? 1 : -selectedTv.qty),
        tvs: state.tvs.map((tv, index) => {
          if (tv.id === payload.id) {
            return {
              ...tv,
              lift: payload.status && tv.id === 3 && "ask",
              price: !payload.status && tv.id === 3 ? tv.liftPrice : tv.price,
              qty: payload.status ? 1 : 0,
              subtotal: payload.status ? selectedTv.price : 0,
            };
          }
          return { ...tv };
        }),
      };
    case ADD_TV:
      return {
        ...state,
        total: state.total + payload.price,
        tvQty: state.tvQty + 1,
        tvs: state.tvs.map((tv, index) => {
          if (tv.id === payload.id) {
            return {
              ...tv,
              qty: tv.qty + 1,
              subtotal: (tv.qty + 1) * payload.price,
            };
          }
          return { ...tv };
        }),
      };

    case UPDATE_LIFT:
      if (payload.lift === true) {
        return {
          ...state,
          tvs: state.tvs.map((tv, index) => {
            if (tv.id === payload.id) {
              return {
                ...tv,
                lift: payload.lift,
              };
            }
            return { ...tv };
          }),
        };
      } else {
        const tv = state.tvs.find((tv) => tv.id === payload.id);
        return {
          ...state,
          total: state.total - tv.qty * (tv.price - tv.noLiftPrice),
          tvs: state.tvs.map((tv, index) => {
            if (tv.id === payload.id) {
              return {
                ...tv,
                lift: payload.lift,
                price: tv.noLiftPrice,
                subtotal: tv.qty * tv.noLiftPrice,
              };
            }
            return { ...tv };
          }),
        };
      }

    case REMOVE_TV:
      const currentTv = state.tvs.find((tv) => tv.id === payload.id);

      return {
        ...state,
        tvs: state.tvs.map((tv, index) => {
          if (tv.id === payload.id) {
            return {
              ...tv,
              qty: tv.qty === 0 ? tv.qty : tv.qty - 1,
              subtotal:
                tv.qty === 0 ? tv.subtotal : (tv.qty - 1) * currentTv.price,
              bracket: false,
            };
          }
          return { ...tv };
        }),
        total:
          state.total <= 0
            ? 0
            : state.total -
              currentTv.price -
              (currentTv.bracket ? currentTv.bracket.price : 0),
        tvQty: state.tvQty <= 0 ? 0 : state.tvQty - 1,
      };

    case TOGGLE_BRACKET:
      const selectedBracket = state.brackets.find(
        (bracket) =>
          bracket.tvid === payload.tvid &&
          (state.tvs.find((tv) => tv.id === payload.tvid).qty === 1 ||
            bracket.id === payload.bracket.id)
      );

      return {
        ...state,
        brackets: payload.status
          ? state.tvs.find((tv) => tv.id === payload.tvid).qty === 1
            ? [
                ...state.brackets.filter(
                  (bracket) => bracket.tvid !== payload.tvid
                ),
                {
                  tvid: payload.tvid,
                  id: payload.bracket.id,
                  price: payload.bracket.price,
                  type: payload.bracket.type,
                  qty: 1,
                },
              ]
            : [
                ...state.brackets,
                {
                  tvid: payload.tvid,
                  id: payload.bracket.id,
                  price: payload.bracket.price,
                  type: payload.bracket.type,
                  qty: 1,
                },
              ]
          : [
              ...state.brackets.filter(
                (bracket) =>
                  bracket.id !== payload.bracket.id ||
                  bracket.tvid !== payload.tvid
              ),
            ],
        bracketQty:
          state.bracketQty +
          (payload.status ? 1 : 0) -
          (selectedBracket ? selectedBracket.qty : 0),
        total:
          state.total +
          (payload.status && payload.bracket.price) -
          (selectedBracket ? selectedBracket.price * selectedBracket.qty : 0),
      };

    case ADD_BRACKET:
      const currentBracket = state.brackets.find(
        (bracket) =>
          bracket.id === payload.bracket.id && bracket.tvid === payload.tvid
      );

      return {
        ...state,
        total: state.total + payload.bracket.price,
        bracketQty: state.bracketQty + 1,
        brackets:
          currentBracket !== undefined
            ? state.brackets.map((bracket, index) => {
                if (
                  bracket.tvid === payload.tvid &&
                  bracket.id === payload.bracket.id
                ) {
                  return {
                    ...bracket,
                    qty: bracket.qty + 1,
                  };
                }

                return { ...bracket };
              })
            : [
                ...state.brackets,
                {
                  tvid: payload.tvid,
                  id: payload.bracket.id,
                  price: payload.bracket.price,
                  type: payload.bracket.type,
                  qty: 1,
                },
              ],
      };

    case REMOVE_BRACKET:
      const bracketToRemove = state.brackets.find(
        (bracket) =>
          bracket.id === payload.bracket.id && bracket.tvid === payload.tvid
      );
      return {
        ...state,
        total:
          bracketToRemove !== undefined
            ? state.total - bracketToRemove.price
            : state.total,
        bracketQty: state.bracketQty !== 0 ? state.bracketQty - 1 : 0,
        brackets: state.brackets.map((bracket) => {
          if (
            bracketToRemove !== undefined &&
            bracket.id === bracketToRemove.id &&
            bracket.tvid === payload.tvid
          ) {
            if (bracket.qty === 1) {
              return {};
            }
            return { ...bracket, qty: bracket.qty - 1 };
          }
          return { ...bracket };
        }),
      };

    case TOGGLE_OPTION:
      const currentOption = state.options.find(
        (option) => option.id === payload.id
      );

      const netDifference = payload.status
        ? currentOption.price
        : -currentOption.price * currentOption.qty;

      return {
        ...state,
        optionsSubtotal: state.optionsSubtotal + netDifference,
        total: state.total + netDifference,
        options: state.options.map((option) => {
          if (option.id === payload.id) {
            return {
              ...option,
              selected: payload.status,
              qty: payload.status ? 1 : 0,
            };
          }
          return { ...option };
        }),
      };

    case INCREASE_OPTION:
      const selectedOption = state.options.find(
        (option) => option.id === payload
      );

      return {
        ...state,
        optionsSubtotal: state.optionsSubtotal + selectedOption.price,
        total: state.total + selectedOption.price,
        options: state.options.map((option) => {
          if (option.id === payload) {
            return {
              ...option,
              qty: option.qty + 1,
            };
          }
          return { ...option };
        }),
      };

    case DECREASE_OPTION:
      const thisOption = state.options.find((option) => option.id === payload);

      return {
        ...state,
        optionsSubtotal: state.optionsSubtotal - thisOption.price,
        total: state.total - thisOption.price,
        options: state.options.map((option) => {
          if (option.id === payload) {
            return {
              ...option,
              qty: option.qty - 1,
              selected: option.qty === 1 ? false : true,
            };
          }
          return { ...option };
        }),
      };

    case TOGGLE_ADDON:
      const currentAddon = state.addOns.find(
        (addOn) => addOn.id === payload.id
      );

      const addOnDifference = payload.status
        ? currentAddon.price
        : -currentAddon.price * currentAddon.qty;

      return {
        ...state,
        addOnsSubtotal: state.addOnsSubtotal + addOnDifference,
        total: state.total + addOnDifference,
        addOns: state.addOns.map((addOn) => {
          if (addOn.id === payload.id) {
            return {
              ...addOn,
              selected: payload.status,
              qty: payload.status ? 1 : 0,
            };
          }
          return { ...addOn };
        }),
      };

    case INCREASE_ADDON:
      const selectedAddon = state.addOns.find((addOn) => addOn.id === payload);

      return {
        ...state,
        addOnsSubtotal: state.addOnsSubtotal + selectedAddon.price,
        total: state.total + selectedAddon.price,
        addOns: state.addOns.map((addOn) => {
          if (addOn.id === payload) {
            return {
              ...addOn,
              qty: addOn.qty + 1,
            };
          }
          return { ...addOn };
        }),
      };

    case DECREASE_ADDON:
      const thisAddon = state.addOns.find((addOn) => addOn.id === payload);

      return {
        ...state,
        addOnsSubtotal: state.addOnsSubtotal - thisAddon.price,
        total: state.total - thisAddon.price,
        addOns: state.addOns.map((addOn) => {
          if (addOn.id === payload) {
            return {
              ...addOn,
              qty: addOn.qty - 1,
              selected: addOn.qty === 1 ? false : true,
            };
          }
          return { ...addOn };
        }),
      };

    case APPLY_DISCOUNT:
      const selectedOptions = state.options.filter(
        (option) => option.selected === true
      );
      const selectedAddOns = state.addOns.filter(
        (addOn) => addOn.selected === true
      );

      const discount =
        (state.tvQty === 2
          ? 10
          : state.tvQty === 3
          ? 20
          : state.tvQty > 3
          ? 40
          : 0) +
        (selectedOptions.length !== 0 || selectedAddOns.length !== 0
          ? state.tvQty === 2
            ? 10
            : state.tvQty > 2
            ? 15
            : 0
          : 0);

      return {
        ...state,
        discount: discount,
      };

    default:
      return state;
  }
}
