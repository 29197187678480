import React from "react";
import { connect } from "react-redux";
//import PropTypes from "prop-types";
import Title from "./layout/Title";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

const Thanks = ({ name }) => {
  // image is already loaded
  const baimg = require("../assets/thanks.png");
  const img = new Image();
  img.src = baimg;
  img.onload = () => {};

  const useStyles = makeStyles(() => ({
    body: {
      minHeight: 896,
      textAlign: "center",
      paddingTop: 36,
      "@media (max-width: 768px)": {
        minHeight: 560,
        paddingTop: 24,
      },
    },
    thanksImg: {
      width: "40%",
      marginTop: 40,
      "@media (max-width: 768px)": {
        width: "80%",
      },
    },
    div: {
      display: "flex",
      alignItems: "center",
      flexFlow: "column",
      marginTop: 30,
      "@media (max-width: 768px)": {},
    },
    thanks: {
      fontSize: 18,
      fontWeight: 500,
      color: "#48465c",
      width: "80%",
      margin: 20,
      "@media (max-width: 768px)": {
        fontSize: 16,
      },
    },
    dummytext: {
      fontSize: 14,
      fontWeight: 500,
      color: "#7c7c7c",
    },

    number: {
      "& a": {
        fontSize: 20,
        fontWeight: 600,
        color: "#77bbff",
        textDecoration: "none",
      },
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Title name="Your request is successfully sent." step="8" />
      <div className={classes.body}>
        <img src={baimg} className={classes.thanksImg} alt="thanks"></img>
        <div className={classes.div}>
          <span className={classes.thanks}>
            Thanks for your request, {name}
            <br />
          </span>
          <span className={classes.dummytext}>
            Please note, this is not a confirmed reservation yet. <br />
            You should receive an update from us shortly! Normally, within the
            next 15 minutes. <br />
            <br />
            If you don't receive an update within that time, please call us at{" "}
            <br />
            <span className={classes.number}>
              <a href="tel:+1-310-773-4010">(310) 773-4010 </a>
            </span>{" "}
          </span>
        </div>
      </div>
    </>
  );
};

Thanks.propTypes = {};

const mapStateToProps = (state) => ({
  name: state.booking.userDetails.name,
});

export default connect(mapStateToProps, {})(Thanks);
