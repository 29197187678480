//Step Actions
export const PREV_STEP = "PREV_STEP";
export const NEXT_STEP = "NEXT_STEP";
export const TOGGLE_NEXT = "TOGGLE_NEXT";
export const EDIT_STEP = "EDIT_STEP";
export const SET_DRAWER = "SET_DRAWER";
export const NEXT_BRACKET = "NEXT_BRACKET";
export const PREV_BRACKET = "PREV_BRACKET";

//Order Actions
export const TOGGLE_TV = "TOGGLE_TV";
export const ADD_TV = "ADD_TV";
export const REMOVE_TV = "REMOVE_TV";
export const UPDATE_LIFT = "UPDATE_LIFT";

export const TOGGLE_BRACKET = "TOGGLE_BRACKET";
export const ADD_BRACKET = "ADD_BRACKET";
export const REMOVE_BRACKET = "REMOVE_BRACKET";

export const TOGGLE_OPTION = "TOGGLE_OPTION";
export const INCREASE_OPTION = "INCREASE_OPTION";
export const DECREASE_OPTION = "DECREASE_OPTION";

export const TOGGLE_ADDON = "TOGGLE_ADDON";
export const INCREASE_ADDON = "INCREASE_ADDON";
export const DECREASE_ADDON = "DECREASE_ADDON";

export const APPLY_DISCOUNT = "APPLY_DISCOUNT";

// Booking Actions
export const ADD_DATE = "ADD_DATE";
export const REMOVE_DATE = "REMOVE_DATE";
export const SET_CAROUSEL_INDEX = "SET_CAROUSEL_INDEX";
export const SET_CURRENT_ITEM = "SET_CURRENT_ITEM";
export const ADD_TIME = "ADD_TIME";
export const REMOVE_TIME = "REMOVE_TIME";
export const TOGGLE_MORE_DAYS = "TOGGLE_MORE_DAYS";
export const SET_DATE_INDEX = "SET_DATE_INDEX";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_VALIDATE_USER = "SET_VALIDATE_USER";
export const SET_SPECIAL_INSTRUCTIONS = "SET_SPECIAL_INSTRUCTIONS";
export const CREATE_BOOKING_REQUEST = "CREATE_BOOKING_REQUEST";
