import React, { useState, useEffect } from "react";
import Title from "./layout/Title";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toggleAddon, increaseAddOn, decreaseAddOn } from "../actions/order";
import { toggleNext } from "../actions/step";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
/* import Popper from "@material-ui/core/Popper";
import PopperCard from "./layout/PopperCard"; */
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Accordion from "@material-ui/core/Accordion";
/* import AccordionSummary from "@material-ui/core/AccordionSummary"; */
import AccordionDetails from "@material-ui/core/AccordionDetails";
/* import ExpandMoreIcon from "@material-ui/icons/ExpandMore"; */
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import FaqModal from "./layout/FaqModal";

const AddOns = ({
  addOns,
  toggleAddon,
  next,
  toggleNext,
  increaseAddOn,
  decreaseAddOn,
  options,
  tvQty,
}) => {
  const useStyles = makeStyles(() => ({
    root1: {
      margin: 0,
      marginBottom: -150,
      paddingBottom: 150,
      opacity: 1,
      position: "relative",
      left: 0,
      fontWeight: 500,
      fontFamily: "Montserrat, sans-serif",
      color: "#000",
      fontSize: 14,

      "@media (max-width: 768px)": {
        animation: "none!important",
        marginBottom: -105,
        paddingBottom: 105,
      },
    },
    paper: {
      marginTop: -30,
      flexGrow: 1,
      borderRadius: 20,
      padding: 17,
      backgroundColor: "#fff",
      opacity: 0.9,
      boxShadow: "0 2px 6px 0 rgba(0, 0, 0, .1)",
      border: "1px solid rgba(218,220,228,.1)",
      "&:hover": {
        opacity: 1,
      },
      "@media (max-width: 768px)": {
        marginTop: -30,
        padding: "10px 8px",
        borderRadius: 10,
        opacity: 1,
        boxShadow: "none",
        border: "none",
      },
    },

    paper2: {
      marginTop: -30,
      flexGrow: 1,
      borderRadius: 20,
      padding: 17,
      backgroundColor: "#fff",
      opacity: 0.9,
      boxShadow: "0 2px 6px 0 rgba(0, 0, 0, .1)",
      border: "1px solid rgba(218,220,228,.1)",
      "&:hover": {
        opacity: 1,
      },
      "@media (max-width: 768px)": {
        padding: "10px 8px",
        borderRadius: 10,
        opacity: 1,
        boxShadow: "none",
        border: "none",
      },
    },
    line: {
      margin: "20px -20px",
      opacity: 0.1,
      "@media (max-width: 768px)": {
        margin: "10px -8px",
      },
    },
    content: {
      fontWeight: 600,
      color: "#a2a2a2",
      fontSize: 13,
      padding: 10,
      "@media (max-width: 768px)": {
        fontSize: 12,
      },
    },
    button: {
      display: "flex",
      justifyContent: "center",
    },
    okBtn: {
      minWidth: 30,
      padding: 0,
      borderRadius: 5,
      color: "#7e7e7e",
      fontSize: 11,
      background: "#d2d0d8",
      fontWeight: 600,
      "@media (max-width: 768px)": {
        fontSize: 10,
        minWidth: 20,
      },
    },

    body: {
      paddingTop: 50,
      minHeight: 731,
      "@media (max-width: 768px)": {
        paddingTop: 30,
        minHeight: 544,
      },
    },

    title1: {
      fontWeight: "bold",
      color: "black",
      fontSize: 12,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px 10px",

      left: 0,
      opacity: 1,
      position: "relative",
      "@media (max-width: 768px)": {
        animation: "none!important",
        opacity: 1,
        fontSize: 10,
      },
    },

    helpIcon: {
      fontSize: "20px !important",
      border: "1px solid #e7e7e7",
      borderRadius: "0px",
      width: "26px",
      color: "#77bbff",
      "@media (max-width: 768px)": {
        fontSize: "12px !important",
        border: "1px solid #e7e7e7",
        borderRadius: "12px",
        width: "16px",
      },
    },
    checkbox: {
      color: "#77bbff !important",
    },

    disabled: {
      opacity: 0.6,
    },

    nameDiv: {
      fontWeight: 500,
      fontSize: 17,
      color: "#000",
      fontFamily: "Montserrat, sans-serif",
      "@media (max-width: 768px)": {
        fontSize: 14,
      },
    },

    qtyDiv: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontWeight: 600,
      fontSize: 18,
      fontFamily: "Montserrat, sans-serif",
      "& button": {
        padding: 0,
      },
      "@media (max-width: 768px)": {
        fontSize: 16,
      },
    },

    addIcon: {
      color: "#77bbff",
    },
    removeIcon: {
      color: "#e7e7e7",
    },

    accordion: {
      color: "black",
      flexGrow: 1,
      backgroundColor: "#f0eff4",
      opacity: 0.9,
      boxShadow: "none",
      border: "none",
      "&:hover": {
        opacity: 1,
      },
      "&:before": {
        content: "none",
      },
      "@media (max-width: 768px)": {
        opacity: 1,
        boxShadow: "none",
        border: "none",
        backgroundColor: "#f0eff4",
      },
    },

    accordionDetails: {
      padding: 0,
      paddingTop: 10,
    },

    container: {
      height: "50px",
    },

    question: {
      color: "#a2a2a2",
      cursor: "pointer",
      fontSize: 12,
      "@media (max-width: 768px)": {
        fontSize: 10,
      },
    },
  }));
  const classes = useStyles();

  const addOnCategories = [
    {
      id: 1,
      name: "HIDE WIRES",
      faqs: [
        {
          question: "What is an external concealment? ",
          answer: "Its a paintable plastic cover.",
        },
        {
          question: "Which parts do you use for Inwall?",
          answer:
            "We mainly use a powerbridge kit. Its a safe product to use and its included.",
        },
        {
          question: "Can you run a power extension cable behind the wall?",
          answer: "No, its not safe and is against the code",
        },
      ],
    },

    {
      id: 2,
      name: "EXTRA INSTALLATIONS",
      faqs: [
        {
          question: "Do you provide a soundar or a shelf?",
          answer: "No, we only install them",
        },
        {
          question: "Do you provide LED lights?",
          answer: "Yes, we do. Its included",
        },
        {
          question: "Do you install big shelves?",
          answer:
            "Yes, we do. Please let us know in comments on Confirmation step #7",
        },
      ],
    },

    {
      id: 3,
      name: "DEVICES AND CABLES",
      faqs: [
        {
          question:
            "Do you set my audio system, cable box or program remote control?",
          answer:
            "We don't offer this as a service. Yet, technician can try to assist with it as a courtesy.",
        },
      ],
    },
  ];

  setTimeout(() => setTime(true), 500);
  const [timeout, setTime] = useState(false);

  /* const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "spring-popper" : undefined;

  const handleClose = (event) => {
    setAnchorEl(null);
  }; */

  const [alert, setAlert] = useState(false);
  const [cableState, setCableState] = useState(false);

  useEffect(() => {
    if (!next) {
      toggleNext(true);
    }
  }, [next, toggleNext]);

  /* if (options[2].selected) {
    addOns = addOns.filter((addOn) => addOn.id !== 2);
  } */

  const [modal, setModal] = useState(false);

  const [modalData, setModalData] = useState({ title: "test", faqs: [] });

  return (
    <div>
      {modal && (
        <FaqModal
          title={modalData.title}
          faqs={modalData.faqs}
          modal={modal}
          setModal={setModal}
        />
      )}
      <Title name={"Need anything else (optional)"} step="4" />
      <div className={classes.body}>
        <div className={classes.root1}>
          {addOnCategories.map((category) =>
            category.id === 3 ? (
              <>
                <br />
                <Grow
                  in={timeout}
                  style={{ transformOrigin: "0 0 0" }}
                  {...{ timeout: 500 * category.id }}
                >
                  <Accordion className={classes.accordion} defaultExpanded>
                    {/*   <div className={classes.title2}>{category.name}</div> */}

                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                      xs={12}
                    >
                      <Grid
                        item
                        className={classes.title1}
                        style={{ padding: "10px 10px" }}
                      >
                        {category.name}
                      </Grid>

                      <Grid
                        item
                        alignItems="center"
                        className={classes.question}
                        onClick={() => {
                          setModal(true);
                          setModalData({
                            title: category.name,
                            faqs: category.faqs,
                          });
                        }}
                      >
                        {" "}
                        <QuestionAnswerIcon
                          style={{
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        HAVE A QUESTION?
                      </Grid>
                    </Grid>

                    <br />

                    <AccordionDetails className={classes.accordionDetails}>
                      <Paper elevation={0} className={classes.paper2}>
                        {addOns
                          .filter((addOn) => addOn.categoryID === category.id)
                          .map((addOn, index) => (
                            <div>
                              {addOn.id === 7 && (
                                <>
                                  <Grid
                                    container
                                    justify="space-between"
                                    alignItems="center"
                                    xs={12}
                                    className={classes.container}
                                    onClick={(e) => setCableState(!cableState)}
                                  >
                                    <Grid key="1">
                                      <Checkbox
                                        checked={cableState}
                                        /*      onChange={(e) =>
                                          setCableState(e.target.checked)
                                        } */
                                        className={classes.checkbox}
                                      />
                                    </Grid>

                                    <Grid key="2" item xs={7} md={7}>
                                      <div className={classes.nameDiv}>
                                        Cables (up to 15 ft) <br />
                                        (All of them $20, except hdmi 2.1 $35)
                                      </div>
                                    </Grid>

                                    <Grid
                                      key="3"
                                      item
                                      xs={3}
                                      md={3}
                                      justify="space-between"
                                      alignContent="center"
                                      alignItems="center"
                                    >
                                      {/*    <div style={{ textAlign: "center" }}>
                                    <IconButton
                                      aria-label="help"
                                      onClick={handleClick}
                                      aria-describedby={id}
                                      className={classes.helpIcon}
                                    >
                                      ?
                                    </IconButton>
                                    <Popper
                                      id={id}
                                      placement="right-start"
                                      disablePortal={false}
                                      onClose={handleClose}
                                      modifiers={{
                                        flip: {
                                          enabled: true,
                                        },
                                        preventOverflow: {
                                          enabled: true,
                                          boundariesElement: "scrollParent",
                                        },
                                      }}
                                      open={open}
                                      anchorEl={anchorEl}
                                      transition
                                    >
                                      <PopperCard handleClose={handleClose} />
                                    </Popper>
                                  </div> */}{" "}
                                    </Grid>
                                  </Grid>
                                  {cableState && (
                                    <hr className={classes.line} />
                                  )}
                                </>
                              )}

                              {(addOn.id < 7 ||
                                (addOn.id > 6 && cableState)) && (
                                <Grid
                                  container
                                  justify="space-between"
                                  alignItems="center"
                                  xs={12}
                                  className={classes.container}
                                  onClick={(e) => {
                                    toggleAddon(addOn.id, !addOn.selected);
                                    addOn.id === 2 && !addOn.selected
                                      ? setAlert(true)
                                      : setAlert(false);
                                  }}
                                >
                                  {addOn.id > 6 && (
                                    <Grid xs={1} md={1}>
                                      {" "}
                                    </Grid>
                                  )}
                                  <Grid key="1" item>
                                    <Checkbox
                                      checked={addOn.selected}
                                      /*         onChange={(e) => {
                                        toggleAddon(addOn.id, e.target.checked);
                                        addOn.id === 2 && e.target.checked
                                          ? setAlert(true)
                                          : setAlert(false);
                                      }} */
                                      className={classes.checkbox}
                                    />
                                  </Grid>

                                  <Grid
                                    key="2"
                                    item
                                    xs={addOn.id > 6 ? 6 : 7}
                                    md={7}
                                  >
                                    <div className={classes.nameDiv}>
                                      {addOn.name}
                                      <br />
                                      {addOn.id < 7 && (
                                        <>
                                          {addOn.price > 0 ? (
                                            <> (+${addOn.price}) </>
                                          ) : (
                                            <>(Free)</>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </Grid>

                                  <Grid
                                    key="3"
                                    item
                                    xs={3}
                                    md={3}
                                    justify="space-between"
                                    alignContent="center"
                                    alignItems="center"
                                  >
                                    {addOn.selected && addOn.qty > 0 && (
                                      <div
                                        className={classes.qtyDiv}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                        }}
                                      >
                                        <IconButton
                                          aria-label="decrease"
                                          onClick={(e) => {
                                            decreaseAddOn(addOn.id);
                                            //e.stopPropagation();
                                          }}
                                        >
                                          <RemoveCircleOutlineIcon
                                            className={classes.removeIcon}
                                          />
                                        </IconButton>
                                        <span>{addOn.qty}</span>
                                        <IconButton
                                          aria-label="increase"
                                          onClick={(e) => {
                                            (category.id === 3 ||
                                              addOn.qty < tvQty) &&
                                              increaseAddOn(addOn.id);
                                            //  e.stopPropagation();
                                          }}
                                        >
                                          <AddCircleOutlineIcon
                                            className={classes.addIcon}
                                          />
                                        </IconButton>
                                      </div>
                                    )}
                                    {/*     <div style={{ textAlign: "center" }}>
                                    <IconButton
                                      aria-label="help"
                                      onClick={handleClick}
                                      aria-describedby={id}
                                      className={classes.helpIcon}
                                    >
                                      ?
                                    </IconButton>
                                    <Popper
                                      id={id}
                                      placement="right-start"
                                      disablePortal={false}
                                      onClose={handleClose}
                                      modifiers={{
                                        flip: {
                                          enabled: true,
                                        },
                                        preventOverflow: {
                                          enabled: true,
                                          boundariesElement: "scrollParent",
                                        },
                                      }}
                                      open={open}
                                      anchorEl={anchorEl}
                                      transition
                                    >
                                      <PopperCard handleClose={handleClose} />
                                    </Popper>
                                  </div>  */}
                                  </Grid>
                                </Grid>
                              )}
                              {cableState
                                ? index + 1 !==
                                    addOns.filter(
                                      (addOn) =>
                                        addOn.categoryID === category.id
                                    ).length && <hr className={classes.line} />
                                : addOn.id < 7 &&
                                  index + 1 !==
                                    addOns.filter(
                                      (addOn) =>
                                        addOn.categoryID === category.id
                                    ).length && <hr className={classes.line} />}
                            </div>
                          ))}
                      </Paper>
                    </AccordionDetails>
                  </Accordion>
                </Grow>
              </>
            ) : (
              <>
                <Grow
                  in={timeout}
                  style={{ transformOrigin: "0 0 0" }}
                  {...{ timeout: 500 * category.id }}
                >
                  <div>
                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                      xs={12}
                    >
                      <Grid item className={classes.title1}>
                        {category.name}
                      </Grid>

                      <Grid
                        item
                        className={classes.question}
                        onClick={() => {
                          setModal(true);
                          setModalData({
                            title: category.name,
                            faqs: category.faqs,
                          });
                        }}
                      >
                        {" "}
                        <QuestionAnswerIcon
                          style={{
                            verticalAlign: "middle",
                          }}
                        />{" "}
                        HAVE A QUESTION?
                      </Grid>
                    </Grid>
                    <br />
                    <Paper elevation={0} className={classes.paper}>
                      {addOns
                        .filter((addOn) => addOn.categoryID === category.id)
                        .map((addOn, index) => (
                          <div
                            onClick={(e) => {
                              toggleAddon(addOn.id, !addOn.selected);

                              addOn.id === 2 && !addOn.selected
                                ? setAlert(true)
                                : setAlert(false);
                            }}
                          >
                            <Grid
                              container
                              justify="space-between"
                              alignItems="center"
                              xs={12}
                              className={classes.container}
                            >
                              {addOn.id > 6 && (
                                <Grid xs={1} md={1}>
                                  {" "}
                                </Grid>
                              )}
                              <Grid key="1" item>
                                <Checkbox
                                  checked={addOn.selected}
                                  /*     onChange={(e) => {
                                    toggleAddon(addOn.id, e.target.checked);
                                    addOn.id === 2 && e.target.checked
                                      ? setAlert(true)
                                      : setAlert(false);
                                  }} */
                                  classes={{
                                    root: classes.checkbox,
                                    disabled: classes.disabled,
                                  }}
                                />
                              </Grid>

                              <Grid
                                key="2"
                                item
                                xs={addOn.id > 6 ? 6 : 7}
                                md={7}
                              >
                                <div className={classes.nameDiv}>
                                  {addOn.name}
                                  <br />
                                  {addOn.id < 7 && (
                                    <>
                                      {addOn.price > 0 ? (
                                        <> (+${addOn.price}) </>
                                      ) : (
                                        <>(Free)</>
                                      )}
                                    </>
                                  )}
                                </div>
                              </Grid>

                              <Grid
                                key="3"
                                item
                                xs={3}
                                md={3}
                                justify="space-between"
                                alignContent="center"
                                alignItems="center"
                              >
                                {addOn.selected && addOn.qty > 0 && (
                                  <div
                                    className={classes.qtyDiv}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                    }}
                                  >
                                    <IconButton
                                      aria-label="decrease"
                                      onClick={(e) => {
                                        decreaseAddOn(addOn.id);
                                        //  e.stopPropagation();
                                      }}
                                    >
                                      <RemoveCircleOutlineIcon
                                        className={classes.removeIcon}
                                      />
                                    </IconButton>
                                    <span>{addOn.qty}</span>
                                    <IconButton
                                      aria-label="increase"
                                      onClick={(e) => {
                                        (category.id === 1 ||
                                          category.id === 3 ||
                                          addOn.id === 3 ||
                                          addOn.qty < tvQty) &&
                                          increaseAddOn(addOn.id);
                                        //  e.stopPropagation();
                                      }}
                                    >
                                      <AddCircleOutlineIcon
                                        className={classes.addIcon}
                                      />
                                    </IconButton>
                                  </div>
                                )}
                                {/*     <div style={{ textAlign: "center" }}>
                                    <IconButton
                                      aria-label="help"
                                      onClick={handleClick}
                                      aria-describedby={id}
                                      className={classes.helpIcon}
                                    >
                                      ?
                                    </IconButton>
                                    <Popper
                                      id={id}
                                      placement="right-start"
                                      disablePortal={false}
                                      onClose={handleClose}
                                      modifiers={{
                                        flip: {
                                          enabled: true,
                                        },
                                        preventOverflow: {
                                          enabled: true,
                                          boundariesElement: "scrollParent",
                                        },
                                      }}
                                      open={open}
                                      anchorEl={anchorEl}
                                      transition
                                    >
                                      <PopperCard handleClose={handleClose} />
                                    </Popper>
                                  </div>  */}
                              </Grid>
                            </Grid>

                            {cableState
                              ? index + 1 !==
                                  addOns.filter(
                                    (addOn) => addOn.categoryID === category.id
                                  ).length && <hr className={classes.line} />
                              : addOn.id < 7 &&
                                index + 1 !==
                                  addOns.filter(
                                    (addOn) => addOn.categoryID === category.id
                                  ).length && <hr className={classes.line} />}
                          </div>
                        ))}
                    </Paper>
                  </div>
                </Grow>
                {alert && category.id === 1 && (
                  <>
                    <div className={classes.content}>
                      Please note in-wall concealment may not be ordered if tv
                      is above a fireplace or on a brick/concrete wall
                    </div>
                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        disableElevation
                        className={classes.okBtn}
                        onClick={() => setAlert(false)}
                      >
                        OK
                      </Button>
                    </div>
                  </>
                )}
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};

AddOns.propTypes = {
  toggleAddon: PropTypes.func.isRequired,
  toggleNext: PropTypes.func.isRequired,
  increaseAddOn: PropTypes.func.isRequired,
  decreaseAddOn: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  addOns: state.order.addOns,
  next: state.step.next,
  options: state.order.options,
  tvQty: state.order.tvQty,
});

export default connect(mapStateToProps, {
  toggleAddon,
  increaseAddOn,
  decreaseAddOn,
  toggleNext,
})(AddOns);
